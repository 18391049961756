// @ts-nocheck
import { bound } from '@whiz-cart/ui-shared/decorators/bound';
import { service } from '@whiz-cart/node-shared/service/service';
import { authService } from '../auth/auth.service';
import config from '../config/config';
import store from '../store';
import endpoint from '../util/endpoint';

class PingService {
    handles = [];

    async start() {
        this.stop();

        const { pingInterval = 15 * 60 * 1000 } = config;

        const timer = setInterval(this.sendNoParams, pingInterval);
        this.handles.push(() => clearInterval(timer));

        this.handles.push(
            store.subscribeState(
                ({ url }) => url.params?.storeGuid,
                (storeGuid, previousStoreGuid) => {
                    this.send(storeGuid, previousStoreGuid);
                },
            ),
        );

        this.handles.push(authService.loginState.subscribe((state) => state.isLoggedIn, this.sendNoParams));

        window.addEventListener('focus', this.sendNoParams);
        this.handles.push(() => window.removeEventListener('focus', this.sendNoParams));
    }

    stop() {
        for (const handle of this.handles) {
            handle();
        }
        this.handles = [];
    }

    @bound sendNoParams() {
        this.send();
    }

    @bound async send(storeGuid, previousStoreGuid) {
        const { isLoggedIn } = authService.loginState.getState();
        if (!isLoggedIn) return;

        if (!storeGuid) storeGuid = await store.awaitState('url.params.storeGuid');
        try {
            await endpoint('ping').post({ storeGuid, previousStoreGuid });
            console.debug('Sent ping', [previousStoreGuid, storeGuid].join(' -> '));
        } catch (e) {
            console.warn('Failed to send ping:', e);
        }
    }
}

export default service('pingService', PingService);
