import { Store } from '@whiz-cart/node-shared/models/store';
import store from '../store';
import storeService from './store.service';

export const UPDATE_STORES = 'UPDATE_STORES';
export const CLEAR_STORES = 'CLEAR_STORES';
export const UPDATE_STORE = 'UPDATE_STORE';

export const loadStores = (stores: Record<string, Store>) => ({
    type: UPDATE_STORES,
    payload: stores,
});

export const clearStores = () => {
    return { type: CLEAR_STORES };
};

storeService.allStores.subscribe(undefined, (stores) => {
    if (stores) {
        store.dispatch(loadStores(stores));
    }
});
