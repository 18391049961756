// @ts-nocheck
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import productBrowserService from '../productBrowser/productBrowser.service';
import store from '../store';
import { checkForChromeResetCode } from '../util/chromeResetCode';

let handler;

export default () => {
    let cancel;
    (async () => {
        const { cooldown = 100, commitKeys = [], ignoreKeys = [] } = await store.awaitState('config.scanner');

        let keys = [];
        let t;

        const registerInput = (e) => {
            // Ignore when typing in input or non-numerical keys
            if (document.activeElement?.nodeName === 'INPUT' || document.activeElement?.nodeName === 'TEXTAREA') return;

            const now = new Date();
            if (now - t > cooldown) keys = [];

            if (commitKeys.includes(e.key) && keys.length) {
                const gtin = keys.join('');
                console.debug(`scan: ${gtin}`);
                handler ? handler(gtin) : route(gtin);
                keys = [];
                e.preventDefault();
                e.target.blur();
            } else if (e.key?.length === 1 && !ignoreKeys.includes(e.key)) {
                keys.push(e.key);
                t = now;
            }
        };

        const route = (gtin) => {
            if (!gtin) return;

            const url = urlService.getUrl();
            const path = url.path.toLocaleLowerCase();
            if (path.match(/mhd/)) {
                urlService.pushUrl(`/product/details/${gtin}/mhd`);
            } else if (path.match('/integritycheck/details/') || path.match('/checkout/payment/')) {
                document.dispatchEvent(new CustomEvent('integrityCheckEvent', { detail: gtin }));
            } else if (checkForChromeResetCode(gtin)) {
                return;
            } else {
                const params = urlService.getUrlParams();
                if (gtin.toString().toLowerCase().includes('/integritycheck/details/')) {
                    urlService.pushUrl(gtin);
                } else {
                    productBrowserService
                        .getProductByBarcode(params?.storeGuid, gtin)
                        .then((prod) => {
                            urlService.pushUrl(`/productBrowser/${prod?.itemId ?? 'unknown'}`);
                        })
                        .catch(() => {
                            console.warn('You may have scanned a User QR Code: ', gtin);
                        });
                }
            }
        };

        document.addEventListener('keydown', registerInput);
        cancel = () => document.removeEventListener('keydown', registerInput);

        window.simulateScan = (text) => (handler ? handler(text) : route(text));
    })();

    return () => cancel?.();
};

export const registerScanHandler = (newHandler) => {
    handler = newHandler;
    return () => {
        if (handler === newHandler) handler = undefined;
    };
};
