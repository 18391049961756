// @ts-nocheck
import Connection from './connection';
import endpoint from '../util/endpoint';
import conectionDebug from './conectionDebug';

const bus = new Connection({
    name: 'session',
    url: ({ storeGuid, sessionGuid }) => storeGuid && sessionGuid && endpoint('signalRSessionMonitor', { storeGuid, sessionGuid }).url,
    dependencies: { storeGuid: 'url.params.storeGuid' },
});
bus.options.debug = conectionDebug;
export default bus;
