// @ts-nocheck
import { SHOW_DEMO_SESSIONS } from './demoSessions.action';

export default (
    state = {
        showDemoSessions: false,
        storeGuid: '',
    },
    action,
) => {
    switch (action.type) {
        case SHOW_DEMO_SESSIONS:
            return {
                ...state,
                showDemoSessions: action.payload,
                storeGuid: action.storeGuid ? action.storeGuid : state.storeGuid,
            };

        default:
            return state;
    }
};
