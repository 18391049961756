// @ts-nocheck
import memoize from '@whiz-cart/node-shared/memoize';
import { LiveSession } from '@whiz-cart/node-shared/models/dsi/liveSession';
import type { Payment } from '@whiz-cart/node-shared/models/payment/payment';
import { UiProduct } from '@whiz-cart/node-shared/models/product/uiProduct';
import { service } from '@whiz-cart/node-shared/service/service';
import _ from 'lodash';
import { Action } from 'schummar-state/react';
import store from '../store';
import endpoint from '../util/endpoint';
import { clearImages, setCashierGuid, updatePayment } from './checkout.action';

class CheckoutService {
    transformIncomingPayment(
        {
            paymentRequest,
            deviceName,
            isMobile,
            loginHash,
            endedOn,
            paymentRequestGuid,
            paymentMethod,
            sessionGuid,
            total,
            processingBy,
            postProcessingBy,
            parkingEan,
            isDemoModeOn,
            isSuspicious,
            posRegistrationCompletedOn,
            completionSource,
            deviceMetadata,
            externalPayment,
            loginType,
            userGuid,
            customer,
        },
        storeGuid,
    ) {
        const _deviceName = deviceName ?? paymentRequest?.cashierId;

        return {
            paymentRequestGuid,
            paymentMethod,
            sessionGuid,
            total,
            processingBy,
            postProcessingBy,
            endedOn,
            deviceName: isMobile ? `E${_deviceName.padStart(3, '0')}` : _deviceName,
            storeGuid,
            isMobile,
            loginHash,
            parkingEan: { code: parkingEan, ...parkingEan },
            isDemoModeOn,
            isBookmarked: isSuspicious,
            posRegistrationCompletedOn,
            completionSource,
            deviceMetadata,
            externalPayment,
            loginType,
            userGuid,
            customer,
            ...paymentRequest,
        };
    }

    addBasketInfo(payment, basket) {
        basket = basket && _.keyBy(basket, 'basketItemGuid');

        const paymentCodes = payment.paymentCodes.map((code) => {
            const [, , sessionCode] = code.code.match(/(XE|XG)(92\d*)X/) || [];
            const [, , customerCard] = code.code.match(/(XE|XG)(995\d{10})X/) || [];
            const [, , employeeCard] = code.code.match(/(XE|XG)(999\d{10}|636453\d*)X/) || [];
            const items =
                basket && _.map(code.basketItemsCounts, (amount, basketItemGuid) => ({ ...basket[basketItemGuid], amount: amount }));
            const total = !items?.length
                ? undefined
                : _.sumBy(items, ({ basketItemGuid, amount }) => {
                      const { totalPrice = 0 } = basket[basketItemGuid] || {};
                      return amount * totalPrice;
                  });

            return { ...code, sessionCode, customerCard, employeeCard, total, items };
        });

        const parkingEan = {
            ...payment.parkingEan,
            customerCard: payment.customerCard,
            employeeCard: payment.employeeCard,
            total: payment.total,
            items: basket && _.values(basket).filter((i) => i.amount > 0),
        };

        return {
            ...payment,
            parkingEan,
            paymentCodes,
            basket,
        };
    }

    getPendingRequests(storeGuid: string) {
        return endpoint('cashier.getPendingRequests', { storeGuid }).get({
            transform: (payments) => payments.map((data) => this.transformIncomingPayment(data, storeGuid)),
        });
    }

    getPaymentHistory(
        storeGuid,
        skip = 0,
        limit = 10,
        {
            from = null,
            to = null,
            cartId = null,
            isExpress = null,
            isQrPayment = null,
            paymentMethods = [],
            userGuid = null,
            isBookmarked = null,
            noPayment = null,
            pos = undefined,
        },
        sessionGuid = null,
        minTotal,
        maxTotal,
        parkingEan,
        receiptNo,
    ) {
        return endpoint('storeManager.getPaymentHistory', { storeGuid, skip, limit }).get({
            params: {
                from,
                to,
                cartId,
                sessionGuid,
                minTotal,
                maxTotal,
                isExpress: paymentMethods.length === 0 && !isExpress ? undefined : isExpress,
                paymentMethods: paymentMethods.length === 0 && !isExpress ? undefined : paymentMethods,
                bookmarkedSessions: isBookmarked || undefined,
                includeUnpaidSessions: noPayment || undefined,
                isQrPayment: isQrPayment || undefined,
                parkingEan,
                userGuid,
                receiptNo,
                includeExternalPayments: true,
                pos,
            },
            transform: ({ items, countPerDay }) => ({
                items: items.map((data) => this.transformIncomingPayment(data, storeGuid)),
                countPerDay,
            }),
        });
    }

    async _updatePayment(endpointName: string | undefined, payment: Partial<Payment>, data: Record<string, unknown>) {
        const { sessionGuid, paymentRequestGuid, storeGuid, cashierGuid } = payment;
        const source = 'storeManager';
        store.dispatch(updatePayment(payment));

        if (endpointName) {
            await endpoint(endpointName, { storeGuid }).post({
                sessionGuid,
                paymentRequestGuid,
                cashierGuid,
                source,
                ...data,
            });
        }
    }

    abortPayment(payment, cashierGuid) {
        return this._updatePayment(
            'cancelPayment',
            { ...payment, status: 4 },
            { cashierGuid: cashierGuid === 'hidePictures' ? '' : cashierGuid },
        );
    }

    confirmPayment(payment, cashierGuid) {
        return this._updatePayment(
            'completePayment',
            { ...payment, status: 2, posRegistrationCompletedOn: true },
            { cashierGuid: cashierGuid === 'hidePictures' ? '' : cashierGuid },
        );
    }

    confirmVerificationItem(payment, item) {
        item = { ...item, approved: true };
        const verificationItems = payment.verificationItems
            .filter((i) =>
                i.verificationItemGuid
                    ? i.verificationItemGuid !== item.verificationItemGuid
                    : i.verificationType !== item.verificationType,
            )
            .concat(item);
        const allApproved = verificationItems.every((i) => i.approved);

        return this._updatePayment(
            item.verificationItemGuid ? 'verifyPaymentVerificationItem' : undefined,
            { ...payment, verificationItems, status: allApproved ? 1 : payment.status },
            { verificationGuid: item.verificationItemGuid, verificationItemGuid: item.verificationItemGuid, approved: true },
        );
    }

    lockPayment(payment, cashierGuid) {
        return this._updatePayment(payment.isPosProcessing ? 'posProcessingLock' : 'lock', payment, {
            cashierGuid: cashierGuid === 'hidePictures' ? '' : cashierGuid,
        });
    }

    unlockPayment(payment, cashierGuid) {
        return this._updatePayment(payment.isPosProcessing ? 'posProcessingUnlock' : 'unlock', payment, {
            cashierGuid: cashierGuid === 'hidePictures' ? '' : cashierGuid,
        });
    }

    @memoize()
    async getSessionAmount(storeGuid, from, to) {
        try {
            const data = await endpoint('getSessionsAmount', { storeGuid, from, to }).get();
            return data;
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get amounts:', e.message);
                throw e;
            }
            return null;
        }
    }

    async loadPayment(storeGuid: string, sessionGuid: string, paymentRequestGuid: string) {
        try {
            const data = (await endpoint('cashier.getPayment', { storeGuid, sessionGuid, paymentRequestGuid }).get()) as any;
            return this.transformIncomingPayment(data, storeGuid);
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get payment:', e.message);
                throw e;
            }
            return null;
        }
    }

    @memoize()
    async loadBasket(storeGuid: string, sessionGuid: string, paymentRequestGuid: string) {
        try {
            return await endpoint('cashier.getPaymentBasket', { storeGuid, sessionGuid, paymentRequestGuid }).get();
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get basket:', e.message);
                throw e;
            }
        }
    }

    loadSession = new Action(async ({ storeGuid, sessionGuid }: { storeGuid: string; sessionGuid: string }) => {
        try {
            return (await endpoint('cashier.getSession', { storeGuid, sessionGuid }).get()) as LiveSession;
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get session:', e.message);
                throw e;
            }
        }
    });

    async loadExpressCheckoutList(storeGuid: string) {
        try {
            const list = (await endpoint('cashier.expressCheckout', { storeGuid }).get()) as any[];
            if (list && list?.length === 0) {
                this.updateExpressCheckoutRegister('hidePictures');
            }
            return list;
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get list:', e.message);
                throw e;
            }
        }
    }

    updateExpressCheckoutRegister({ cashierGuid, cashierId }: { cashierGuid: string; cashierId: string }) {
        localStorage.setItem('localCashier', cashierGuid === 'hidePictures' ? 'hidePictures' : cashierId);
        store.dispatch(setCashierGuid(cashierGuid));
    }

    clearExpressImages() {
        store.dispatch(clearImages());
    }

    async getSessionByReceiptNumber(storeGuid: string, receiptNumber: string) {
        try {
            return await endpoint('storeManager.getSessionByReceiptNumber', { storeGuid, receiptNumber }).get();
        } catch (e) {
            if (e.data.status === 110) return null;
            throw e;
        }
    }

    getProductInformation = new Action(async ({ storeGuid, barcode }: { storeGuid?: string; barcode: string }) => {
        if (!storeGuid) {
            return null;
        }
        const result = await endpoint('cashier.getProductInformation', { storeGuid }).get({ params: { storeGuid, barcode } });
        return result as UiProduct;
    });
}

export default service('checkoutService', CheckoutService);

export type MposVersion = 'mposv1' | 'mposv2' | null;
