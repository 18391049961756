import { Locale } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { Action } from 'schummar-state/react';
import { useTranslator } from '../translate';

const locales: Record<string, () => Promise<{ default: Locale }>> = {
    en: () => import('date-fns/locale/en-US'),
    de: () => import('date-fns/locale/de'),
    nb: () => import('date-fns/locale/nb'),
};

const load = new Action(async (locale: string) => {
    try {
        const module = await locales[locale]?.();
        return module?.default as Locale;
    } catch (e) {
        console.error('Failed to load locale', e);
        return null;
    }
});

export function useDateFnsLocale(locale?: string) {
    const t = useTranslator();
    const [dfns] = load.useAction(locale ?? t.locale);

    return dfns ?? enUS;
}
