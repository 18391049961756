import { cloneElement, ReactElement, ReactNode } from 'react';

export function FlatProviders({ providers, children }: { providers: ReactElement[]; children?: ReactNode }) {
    let node = children;

    for (const provider of providers.reverse()) {
        node = cloneElement(provider, {}, node);
    }

    return <>{node}</>;
}
