import endpoint from '@util/endpoint';
import { Brand } from '@whiz-cart/node-shared/models/brand/brand';
import { defaultBrand } from '@whiz-cart/ui-shared/brand/defaultBrand';
import { service } from '@whiz-cart/node-shared/service/service';
import { orderBy } from 'lodash';
import { Action } from 'schummar-state/react';
import { z } from 'zod';
import { BrandDraft } from './types/brandDraft';

export const brandEditorService = service(
    'brandEditorService',
    class BrandEditorService {
        list = new Action(async () => {
            try {
                const response = await endpoint('storeManager.brandingList').get();
                const brands = Brand.array().parse(response);

                return [defaultBrand, ...orderBy(brands, '_id')];
            } catch (e) {
                console.error('Failed to fetch brands:', e);
                throw e;
            }
        });

        get = new Action(async (brandId: string) => {
            try {
                if (brandId === 'easyshopper') {
                    // default brand is built in
                    return undefined;
                }

                const response = await endpoint('storeManager.brandingGet', { brandId }).get();
                const brand = Brand.parse(response);
                return brand;
            } catch (e) {
                console.error('Failed to fetch brand:', e);
                throw e;
            }
        });

        useBrand(id?: string): [Brand | undefined, { error?: unknown; isLoading: boolean }] {
            const [brands, info] = this.list.useAction(undefined);

            return [brands?.find((brand) => brand._id === id), info];
        }

        async save(brand: BrandDraft, oldId?: string) {
            if (brand._id !== oldId) {
                const allBrands = await this.list.execute(undefined);
                if (allBrands.some((other) => other._id === brand._id)) {
                    throw Object.assign(new Error(`Id is already taken: ${brand._id}`), { type: 'duplicateId' });
                }
            }

            const imagesPromises = Object.entries(brand.images).map(async ([key, image]) => {
                return [key, image instanceof File ? await this.uploadImage(image) : image] as [string, string | null];
            });
            const customerCardsPromises = Object.entries(brand.customerCards).map(async ([_, card]) => {
                if (card.image instanceof File) {
                    card.image = await this.uploadImage(card.image);
                }
                return [card.id, card] as [string, BrandDraft['customerCards'][string]];
            });

            const images = Object.fromEntries(await Promise.all(imagesPromises));
            const customerCards = Object.fromEntries(await Promise.all(customerCardsPromises));

            if (oldId && oldId !== brand._id) {
                await endpoint('storeManager.brandingRename').post({
                    oldId,
                    newId: brand._id,
                });
            }

            await endpoint('storeManager.brandingUpdate', { id: brand._id }).post({
                ...brand,
                images,
                customerCards,
            });

            this.list.invalidateCacheAll();
        }

        async uploadImage(image: File) {
            const formData = new FormData();
            formData.append('file', image);

            const response = await endpoint('storeManager.brandingUpload').post(formData);
            const url = z.string().parse(response);

            return url;
        }

        async delete(id: string) {
            await endpoint('storeManager.brandingDelete', { id }).delete();

            this.list.invalidateCacheAll();
        }
    },
);
