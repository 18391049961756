// @ts-nocheck
import { Store } from '@whiz-cart/node-shared/models/store';
import { CLEAR_STORES, UPDATE_STORE, UPDATE_STORES } from './store.action';

export default (state: { stores: Record<string, Store> } = { stores: {} }, { type, payload }) => {
    switch (type) {
        case UPDATE_STORES:
        case CLEAR_STORES:
            return { ...state, stores: payload || {}, isLoaded: type === UPDATE_STORES };
        case UPDATE_STORE:
            return { ...state, stores: { ...state.stores, [payload.storeGuid]: payload } };
        default:
            return state;
    }
};
