import endpoint from '../util/endpoint';
import { Action } from 'schummar-state/react';
import { IntegrityCheckItemContent, integrityCheckItemResponse } from './integrityCheckTypes';

export default window.integrityCheckService = new (class {
    async getPendingIntegrityChecks({ storeGuid, skip, limit }: { storeGuid: string; skip: number; limit: number }) {
        const pendingIntegrityChecks = await endpoint('cashier.getPendingIntegrityCheckRequest', { storeGuid }).get({
            params: { storeGuid, skip, limit },
        });
        return pendingIntegrityChecks;
    }

    getIntegrityCheckItem = new Action(async ({ storeGuid, paymentRequestGuid }: { storeGuid: string; paymentRequestGuid: string }) => {
        try {
            const data = await endpoint('cashier.getIntegrityCheckItem', { storeGuid }).get({ params: { paymentRequestGuid } });
            return data as IntegrityCheckItemContent;
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get Integrity Check Item:', e.message);
                throw e;
            }
            return null;
        }
    });

    async updateIntegrityCheck(storeGuid: string, paymentRequestGuid: string, barcode?: string) {
        const updatedItem = await endpoint('cashier.updateIntegrityCheck', { storeGuid }).post({ storeGuid, paymentRequestGuid, barcode });
        const parsed = integrityCheckItemResponse.parse(updatedItem);
        this.getIntegrityCheckItem.setCache({ storeGuid, paymentRequestGuid }, parsed.content);
        return parsed;
    }

    async confirmSiblingProduct(itemId: string, storeGuid: string, paymentRequestGuid: string) {
        const result = await endpoint('cashier.confirmSiblingProduct', { storeGuid }).post({ storeGuid, itemId, paymentRequestGuid });
        const parsed = integrityCheckItemResponse.parse(result);
        this.getIntegrityCheckItem.setCache({ storeGuid, paymentRequestGuid }, parsed.content);
        return parsed;
    }

    async cancelIntegrityCheck(storeGuid: string, paymentRequestGuid: string, cancelReason: string) {
        const canceledItem = await endpoint('cashier.cancelIntegrityCheck', { storeGuid }).post({
            storeGuid,
            paymentRequestGuid,
            cancelReason,
        });
        return canceledItem;
    }

    async continueFailedIntegrityCheck(storeGuid: string, paymentRequestGuid: string) {
        const finishedItem = await endpoint('cashier.continueFailed', { storeGuid }).post({ storeGuid, paymentRequestGuid });
        return finishedItem;
    }
})();
