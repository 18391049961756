// @ts-nocheck
import { updateProduct } from '@product/product.action';
import { ConnectionStatus } from '@whiz-cart/node-shared/connection/connection';
import genGuid from '@whiz-cart/node-shared/guid';
import { SwSync } from '@whiz-cart/ui-shared/sync/swSync';
import { Sync } from '@whiz-cart/ui-shared/sync/sync';
import _ from 'lodash';
import { hasClaim } from '../auth/authHelpers';
import bus from '../connection/storeManagerConnection';
import store from '../store';
import endpoint from '../util/endpoint';

const deviceId = genGuid();
const swSync = new SwSync('mhd');

let cancel;

const transformIncoming = <T extends any[]>(...items: T): { [K in keyof T]: any } => {
    const productsUi: any[] = [];

    const transformed = items.map(({ barcode, expireOn, amount, updatedOn, updatedBy, productUi, mhdGroup, unknownProduct }) => {
        if (productUi) {
            productsUi.push({ ...productUi, mhdGroup });
        }
        return {
            id: `${bus.config.storeGuid}:${barcode}`,
            storeGuid: bus.config.storeGuid,
            barcode,
            expireOn,
            amount,
            updatedOn,
            updatedBy,
            mhdGroup: unknownProduct ? unknownProduct.mhdGroup : mhdGroup,
        };
    }) as T;

    if (productsUi.length) {
        store.dispatch(updateProduct(...productsUi));
    }

    return transformed;
};

const transformOutgoing = ({ barcode, expireOn, amount }) => ({
    storeGuid: bus.config.storeGuid,
    barcode,
    expireOn,
    amount,
    updatedBy: deviceId,
});

const on = (messageListener, reconnectListener) => {
    const cancelMessageListener = bus.subscribe('productExpirationUpdate', (item) => messageListener(transformIncoming(item)[0]));
    const cancelStatusListener = bus.subscribeStatus((status) => status === ConnectionStatus.Connected && reconnectListener());
    cancel = () => {
        cancelMessageListener();
        cancelStatusListener();
    };
};
const off = () => cancel?.();
const get = async () => {
    if (!bus.config.storeGuid) return [];

    if (!hasClaim()('product/expiration/write', 'store', bus.config.storeGuid)) return [];

    const maxDate =
        _(store.getState().mhd.items)
            .values()
            .filter((item) => item.storeGuid === bus.config.storeGuid)
            .map((item) => new Date(item.updatedOn))
            .max() || new Date(0);

    return endpoint('getMHDList', { storeGuid: bus.config.storeGuid })
        .post({ from: maxDate.toISOString() })
        .then((items: any) => transformIncoming(...items));
};
const update = (item) => swSync.queue(transformOutgoing(item));

export default new Sync({
    name: 'mhd',
    connection: { on, off, get, update },
    dispatch: (...args) => store.dispatch(...args),
    deviceId: () => deviceId,
});
