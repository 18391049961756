import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { authService } from '../auth/auth.service';
import { hasSingleStore } from '../auth/authHelpers';
import storeService from '../store/store.service';

export default () => {
    return authService.loginState.subscribe(
        (state) => state.isLoggedIn,
        (isLoggedIn, prevIsLoggedIn) => {
            if (isLoggedIn && !prevIsLoggedIn) {
                authService.claimMapping.get(undefined);
                storeService.allStores.get(undefined);
            }

            if (isLoggedIn && prevIsLoggedIn === false) {
                const singleStore = hasSingleStore(authService.loginState.getState());
                if (singleStore) {
                    urlService.replaceUrl({ storeGuid: singleStore });
                }
            }
        },
    );
};
