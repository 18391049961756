import { faMapMarkerAlt, faSignOutAlt, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import List from '@mui/material/List';
import { ClickOutside } from '@whiz-cart/ui-shared/clickOutside/clickOutside.component';
import { loadingValue } from '@whiz-cart/ui-shared/decorators/loading';
import { Img } from '@whiz-cart/ui-shared/img/img.component';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { groupBy, isEmpty, map } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { version } from '../../package.json';
import { authService } from '../auth/auth.service';
import { useAuth } from '../auth/useAuth';
import { pushService } from '../push/push.service';
import { Route, useVisibleRoutes } from '../routes';
import serviceVersionsService from '../serviceVersions/serviceVersions.service';
import { useTranslator } from '../translate';
import { toggleNavigation } from './navigation.action';
import css from './navigation.module.less';
import NavigationGroup from './navigationGroup';
import StoreSelectAutocomplete from './storeSelectAutocomplete';
import useInputFocusShortcut from '@util/useInputFieldFocus';
import { textMatch } from 'schummar-table';

export type RouteLike = Pick<Route, 'path' | 'link' | 'icon' | 'label' | 'englishLabel'> & {
    exact?: Route['exact'];
    children?: RouteLike[];
    onClick?: () => void;
    isMatchingSearch?: boolean;
    isDirectMatch?: boolean;
};

const routeMatch = (route: RouteLike, query: string): RouteLike => {
    if (!query) {
        return route;
    }

    const children = route.children?.map((child) => routeMatch(child, query));
    const childMatches = children?.some((child) => child.isMatchingSearch);
    const isDirectMatch = textMatch(route.label ?? '', query) || textMatch(route.englishLabel ?? '', query);

    return {
        ...route,
        isMatchingSearch: childMatches || isDirectMatch,
        isDirectMatch,
        children,
    };
};

export default function Navigation() {
    const { tokenDetails, getActiveRoles } = useAuth();
    const username = tokenDetails?.unique_name;
    const t = useTranslator();
    const navigation = useSelector((state: any) => state.navigation);
    const stores = useSelector((state: any) => state.stores.stores);
    const dispatch = useDispatch();
    const hideNavigation = () => {
        resetSearch();
        if (document.body.offsetWidth > 599) return;
        if (navigation) dispatch(toggleNavigation(false));
    };
    const [searchQuery, setSearchQuery] = useState('');
    const [focusedSearchResultIndex, setFocusedSearchResultIndex] = useState(0);

    const showVersions = () => {
        serviceVersionsService.show();
    };

    const logout = async () => {
        await pushService.unsubscribeAll();
        authService.logout();
    };

    const routes = [
        ...useVisibleRoutes(),
        {
            path: '/account',
            link: '/me',
            icon: faUser,
            label: username,
            group: 'navigation.account',
        },
        {
            path: '/logout',
            link: '/logout',
            onClick: logout,
            icon: faSignOutAlt,
            label: t('navigation.logout'),
            group: 'navigation.account',
        },
    ];

    const searchFieldRef = useRef<HTMLInputElement>();
    const { modifierText } = useInputFocusShortcut('k', searchFieldRef.current, {
        onOtherKeyPress: handleKeyPress,
        onFocus: () => {
            setFocusedSearchResultIndex(0);
        },
    });

    const filteredRoutes = routes.map((route) => routeMatch(route, searchQuery)).filter((route) => !searchQuery || route.isMatchingSearch);
    const focusableFlatRoutes = filteredRoutes
        .flatMap((route) => (route?.children ? [route, ...route.children] : [route]))
        .filter((route) => route.isDirectMatch);

    const groups = groupBy(filteredRoutes, 'group');
    const activeRoles = getActiveRoles();
    const hasNoRoles = Object.entries(activeRoles).length === 0;

    function resetSearch() {
        setSearchQuery('');
    }

    function isHighlighted(route: RouteLike) {
        const activeRoute = focusableFlatRoutes[focusedSearchResultIndex];
        return activeRoute && route.link === activeRoute.link;
    }

    function handleKeyPress(e: KeyboardEvent) {
        if (document.activeElement !== searchFieldRef.current) {
            return;
        }
        if (e.key === 'ArrowDown') {
            setFocusedSearchResultIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                if (nextIndex >= focusableFlatRoutes.length) {
                    return 0;
                }
                return nextIndex;
            });
        } else if (e.key === 'ArrowUp') {
            setFocusedSearchResultIndex((prevIndex) => {
                const nextIndex = prevIndex - 1;
                if (nextIndex < 0) {
                    return focusableFlatRoutes.length - 1;
                }
                return nextIndex;
            });
        } else if (e.key === 'Enter') {
            const activeRoute = focusableFlatRoutes[focusedSearchResultIndex];
            if (activeRoute) {
                searchFieldRef.current?.blur();
                urlService.pushUrl(activeRoute.link);
                hideNavigation();
                setFocusedSearchResultIndex(0);
            }
        } else if (e.key === 'Escape') {
            searchFieldRef.current?.blur();
            hideNavigation();
            setFocusedSearchResultIndex(0);
        } else {
            return;
        }
        e.preventDefault();
    }

    return (
        <ClickOutside data-testid="navigationBarContainer" onClickOutside={hideNavigation} className={css.navigation}>
            <Link to={urlService.calcUrl('/')} className={css.logoWrapper}>
                <Img data-testid="logo" className={css.logo} src={{ brandImage: 'logoWhite' }} />
            </Link>

            <div data-testid="navigationStore" className={css.store}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {loadingValue(<StoreSelectAutocomplete stores={stores} onSelect={resetSearch} />, {
                    check: !isEmpty(stores),
                    label: !hasNoRoles ? t('navigation.loading') : t('navigation.notAvailable'),
                    className: !hasNoRoles ? css.loading : css.unavailable,
                })}
            </div>

            <List className={css.container}>
                <div data-testid="navigationItems" className={css.items}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                            endAdornment: <div className={css.shortcutAdornment}>{modifierText}</div>,
                        }}
                        inputRef={searchFieldRef}
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setFocusedSearchResultIndex(0);
                        }}
                        className={css.searchField}
                        placeholder={t('search')}
                    />
                    {isEmpty(stores) && hasNoRoles && <div className={css.notAssigned}>{t('navigation.notAssigned')}</div>}
                    {map(groups, (routes, groupName) => (
                        <NavigationGroup
                            key={`navgroup-${groupName}`}
                            routes={routes}
                            groupName={groupName}
                            hideNavigation={hideNavigation}
                            disableCollapse={!!searchQuery}
                            highlightItem={searchQuery.length > 0 ? isHighlighted : undefined}
                        />
                    ))}

                    <div className={css.spacer} />

                    <Button variant="text" data-testid="navigationItem-version" className={css.version} onClick={showVersions}>
                        {t('navigation.version', { version })}
                    </Button>
                </div>
            </List>
        </ClickOutside>
    );
}
