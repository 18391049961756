import type {} from '@mui/lab/themeAugmentation';
import { createTheme, Theme as MuiTheme } from '@mui/material';
import { t } from './translate';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

export const theme = createTheme({
    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'white',
                    color: '#333',
                    border: '1px solid #333',
                    fontSize: '1rem',
                },
                arrow: {
                    '&::before': {
                        border: '1px solid #333',
                        backgroundColor: '#fff',
                        boxSizing: 'border-box',
                    },
                },
            },
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'inherit',
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
                variant: 'outlined',
            },
        },
        MuiSelect: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'filled',
            },
        },
        MuiCard: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: 'primary',
            },
        },
        MuiLoadingButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                variant: 'text',
                color: 'inherit',
                size: 'small',
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    [breakpoints.down('sm')]: {
                        margin: '0 10px',
                        width: 'calc(100% - 20px)',
                    },
                },
            },
        },

        MuiDatePicker: {
            defaultProps: {
                okText: t('ok'),
                cancelText: t('cancel'),
                clearText: t('clear'),
            },
        },
        ['MuiTimePicker' as any]: {
            defaultProps: {
                okText: t('ok'),
                cancelText: t('cancel'),
                clearText: t('clear'),
                DialogProps: {
                    PaperProps: {
                        sx: { '& *:focus': { outline: 'none' } },
                    },
                },
                PopperProps: {
                    sx: { '& *:focus': { outline: 'none' } },
                },
            },
        },
    },

    palette: {
        primary: { main: '#84bd00', light: '#BCD47D', dark: '#60742f', contrastText: '#fff' },
        secondary: { main: '#0D99FF', light: '#800d99ff', dark: '#00497e', contrastText: '#fff' },
        warning: { main: '#ee8100', contrastText: '#fff' },
        error: { main: '#d30c2a', contrastText: '#fff' },
        success: { main: '#84bd00', contrastText: '#fff' },
        info: { main: '#0D99FF', contrastText: '#fff' },
        neutral: { main: '#333', contrastText: '#fff' },
        text: { primary: '#333', secondary: '#666', disabled: '#999' },
        common: { black: '#333', white: '#fff' },
    },

    typography: {
        fontFamily: `'Fira Sans', sans-serif`,
        fontWeightRegular: 400,
        body1: { fontSize: '0.8rem' },
        caption: { fontSize: '0.6rem' },
        subtitle1: { fontSize: '0.8rem' },
        button: { textTransform: 'none' },
        h1: { fontSize: '1.3rem' },
        h2: { fontSize: '1.1rem', fontWeight: 'bold' },
        h3: { fontSize: '0.8rem', fontWeight: 'bold' },
    },
    shape: {
        // borderRadius: 20,
        borderRadius: 5,
    },
});

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }
    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
    }
}

// Extend color prop on components
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}
declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        neutral: true;
    }
}

declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Theme extends MuiTheme {}
}
