import moment from 'moment';
import { useEffect } from 'react';
import { useTranslator } from '../translate';

export function useConfigureLocales() {
    const t = useTranslator();

    useEffect(() => {
        moment.locale(t.locale);
    }, [t.locale]);
}
