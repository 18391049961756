// @ts-nocheck
import endpoint from '../util/endpoint';
import { toast } from 'react-toastify';
import store from '../store';

export const UPDATE_CONFIGSETTINGS = 'UPDATE_CONFIGSETTINGS';
export const GET_CONFIGSETTINGS = 'GET_CONFIGSETTINGS';
export const GET_GROUPCONFIGSETTINGS = 'GET_GROUPCONFIGSETTINGS';

export const updateConfigSuccess = (config) => ({ type: UPDATE_CONFIGSETTINGS, payload: config });
export const getConfigSuccess = (config) => ({ type: GET_CONFIGSETTINGS, payload: config });
export const getGroupConfigSuccess = (config) => ({ type: GET_GROUPCONFIGSETTINGS, payload: config });

export const fetchConfig = () => async (dispatch) => {
    const storeGuid = await store.awaitState('url.params.storeGuid');
    try {
        const response = await endpoint('getConfigValues', { storeGuid }).get();

        if (!response) {
            dispatch(getConfigSuccess(null));
            toast.error('Config nicht gefunden');
            return;
        }
        dispatch(getConfigSuccess(response));
    } catch (e) {
        console.error(`Could not fetch Config Values`, e);
    }
};

export const updateConfig = (groupName, changedData) => async (dispatch) => {
    const storeGuid = await store.awaitState('url.params.storeGuid');
    try {
        const response = await endpoint('updateConfigValue', { storeGuid, groupName }).post(changedData);
        if (!response) {
            const configData = {
                groupName: groupName,
                configs: changedData,
            };
            dispatch(updateConfigSuccess(configData));
        }
        toast.success('Einstellungen gespeichert');
    } catch (e) {
        toast.error('Fehler beim speichern');
        console.error(`Could not save Config Values`, e);
        throw e;
    }
};

export const fetchGroupConfigSettings = (groupName) => async (dispatch) => {
    const storeGuid = await store.awaitState('url.params.storeGuid');
    try {
        const response = await endpoint('getGroupConfigValues', { storeGuid, groupName }).get();
        if (!response) {
            dispatch(getGroupConfigSuccess(null));
            //toast.error('Config Gruppe nicht gefunden');
            return;
        } else {
            if (response.configs.length > 0) {
                dispatch(getGroupConfigSuccess(response));
            }
        }
    } catch (e) {
        console.error(`Could not fetch Config Values`, e);
    }
};
