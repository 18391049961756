// @ts-nocheck
import Connection from './connection';
import endpoint from '../util/endpoint';
import conectionDebug from './conectionDebug';

const bus = new Connection({
    name: 'storeManager',
    url: ({ storeGuid }) => storeGuid && endpoint('signalR', { storeGuid }).url,
    dependencies: { storeGuid: 'url.params.storeGuid' },
});
bus.options.debug = conectionDebug;
export default bus;
