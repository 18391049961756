// @ts-nocheck
import { Button } from '@whiz-cart/ui-shared/button/button.component';
import { Form } from '@whiz-cart/ui-shared/form/form.component';
import { PasswordField } from '@whiz-cart/ui-shared/form/passwordField';
import { t } from '../translate';
import { commonValidations } from '@whiz-cart/ui-shared/validations/commonValidations';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import userService from './user.service';
import css from './userActivation.module.less';

const errorCodes = {
    invalidToken: 34006,
};

const validations = {
    password: commonValidations.password,
};

const validationMessages = {
    password: (x: string) => t('validation.length', { length: x.length }),
};

export default function UserActivation() {
    const { token, type, userName } = useSelector((state: any) => state.url.params) ?? {};

    async function save({ password }: any, { setError }: any) {
        try {
            await userService.activate({ token, userName, password: password.value, type });
        } catch (e) {
            console.error('Failed to active user', e);

            const knownError = _.findKey(errorCodes, (x) => x === e.data?.status);
            if (knownError) setError('password', t(`profile.activation.${knownError}`));
            else setError('password', t('profile.login.unknownError'));
        }
    }

    return (
        <Form
            className={css.view}
            values={{ password: '' }}
            action={save}
            validations={validations}
            validationMessages={validationMessages}
        >
            {({ password }: any, { submit, valid, processing }: any) => (
                <>
                    <div className={css.title}>{t.unknown(`profile.activation.${type}`, undefined, { fallback: 'invalid type' })}</div>

                    <label>{t('profile.userName')}</label>
                    <div className={css.value}>{userName}</div>

                    <label htmlFor="password" className={css.label}>
                        {t('profile.newPassword')}
                    </label>
                    <PasswordField
                        id="password"
                        value={password.value ?? ''}
                        onChange={(e) => password.onChange(e.currentTarget.value)}
                        autoComplete="new-password"
                        reveal
                    />
                    <div className={css.error}>{password.validationError}</div>

                    <Button className={css.button} primary onClick={submit} inactive={!valid || processing} spinner={processing}>
                        {t('profile.activation.submit')}
                    </Button>
                </>
            )}
        </Form>
    );
}
