// @ts-nocheck
import { UPDATE_CART_LOGIN_TOKENS } from './cartLogin.action';

export default (state = { tokens: [] }, { type, tokens }) => {
    switch (type) {
        case UPDATE_CART_LOGIN_TOKENS:
            return { ...state, tokens };
        default:
            return state;
    }
};
