// @ts-nocheck
import endpoint from '../util/endpoint';
export const TOGGLE_PRODUCT_START = 'TOGGLE_PRODUCT_START';
export const TOGGLE_PRODUCT_SUCCEED = 'TOGGLE_PRODUCT_SUCCEED';
export const LOAD_PRODUCTS_LIST = 'LOAD_PRODUCTS_LIST';

export const toggleProductSucceed = (products, itemId, forceActive) => ({
    type: TOGGLE_PRODUCT_SUCCEED,
    payload: itemId,
    forceActive,
    products,
});
export const loadProductsList = (list) => ({ type: LOAD_PRODUCTS_LIST, payload: list });

export const toggleProductStart = (products, storeGuid, itemId, forceActive) => async (dispatch) => {
    dispatch({ type: TOGGLE_PRODUCT_START, payload: itemId, products });
    let activeStatus;
    if (forceActive === null) {
        activeStatus = true;
    } else {
        activeStatus = !forceActive;
    }
    try {
        const product = endpoint('updateStoreProduct', { storeGuid }).post({
            itemId,
            forceActive: activeStatus,
        });
        dispatch(toggleProductSucceed(products, itemId, forceActive));
        return product;
    } catch (e) {
        if (e.response?.status !== 404) {
            console.error('Failed to update product:', e.message);
            throw e;
        }
        return null;
    }
};
