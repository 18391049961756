// @ts-nocheck
export const ADD_TO_TITLE_BAR = 'ADD_TO_TITLE_BAR';
export const REMOVE_FROM_TITLE_BAR = 'REMOVE_FROM_TITLE_BAR';
export const ADD_ACTION = 'ADD_ACTION';
export const REMOVE_ACTION = 'REMOVE_ACTION';

export const addToTitleBar = (params) => ({ type: ADD_TO_TITLE_BAR, payload: params });

export const removeFromTitleBar = (params) => ({ type: REMOVE_FROM_TITLE_BAR, payload: params });

export const addAction = (action) => ({ type: ADD_ACTION, payload: action });

export const removeAction = (id) => ({ type: REMOVE_ACTION, payload: id });
