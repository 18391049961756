// @ts-nocheck
import { ADD_ACTION, ADD_TO_TITLE_BAR, REMOVE_ACTION, REMOVE_FROM_TITLE_BAR } from './titleBar.action';
import _ from 'lodash';

const getCurrent = (stack) =>
    Object.assign(
        {},
        ..._(stack)
            .orderBy('priority')
            .map((x) => _.omitBy(x, _.isUndefined))
            .value(),
    );

export default (state = { stack: [], current: {}, actions: [] }, { type, payload }) => {
    let stack;

    switch (type) {
        case ADD_TO_TITLE_BAR:
            stack = [...state.stack, payload];
            const multi = _(stack)
                .groupBy('priority')
                .filter((x) => x.length > 1)
                .value();
            if (multi.length > 0) console.warn('Multiple titles set with same priority!', multi);

            return { ...state, stack, current: getCurrent(stack) };

        case REMOVE_FROM_TITLE_BAR:
            stack = state.stack.filter((x) => x !== payload);
            return { ...state, stack, current: getCurrent(stack) };

        case ADD_ACTION:
            return {
                ...state,
                actions: state.actions.filter((a) => a.id !== payload.id).concat(payload),
            };
        case REMOVE_ACTION:
            return { ...state, actions: state.actions.filter((a) => a.id !== payload) };
        default:
            return state;
    }
};
