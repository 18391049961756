// @ts-nocheck
import { authService } from '../auth/auth.service';
import config from '../config/config';
import store from '../store';
import endpoint from '../util/endpoint';
import { updateCartLoginTokens } from './cartLogin.action';

export default () => {
    const fetch = async () => {
        if (!authService.loginState.getState().isLoggedIn) return;

        try {
            const tokens = await endpoint('storeManager.cartTokens').get();
            store.dispatch(updateCartLoginTokens(tokens));
            console.debug(`Updated ${tokens.length} cart login tokens`);
        } catch (e) {
            console.warn('Failed to fetch cart login tokens:', e);
        }
    };

    let timer;
    const cancelSubscribe = authService.loginState.subscribe(
        (state) => state.isLoggedIn,
        (isLoggedIn) => {
            clearInterval(timer);

            if (isLoggedIn) {
                fetch();
                timer = setInterval(fetch, config.cartLoginUpdateInterval);
            }
        },
    );

    return [() => clearInterval(timer), cancelSubscribe];
};
