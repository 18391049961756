// @ts-nocheck
import {
    PRODUCTS_LIST,
    SEARCH_PRODUCTS_LIST,
    LOADING_SEARCH_PRODUCT_LIST,
    CLEAR_SEARCH_PRODUCTS_LIST,
    ADD_PRODUCT,
    DELETE_PRODUCT,
    CLEAR_ITEM,
} from './noBarcode.action';

export default (
    state = {
        productsList: [],
        searchProductList: [],
        currentItem: '',
    },
    { type, payload },
) => {
    switch (type) {
        case PRODUCTS_LIST:
            return { ...state, productsList: payload };
        case LOADING_SEARCH_PRODUCT_LIST:
            return { ...state, isLoading: true };
        case SEARCH_PRODUCTS_LIST:
            return { ...state, searchProductList: payload, isLoading: false };
        case CLEAR_SEARCH_PRODUCTS_LIST:
            return { ...state, searchProductList: [] };
        case ADD_PRODUCT:
            return { ...state, productsList: [payload, ...state.productsList], currentItem: payload.itemId };
        case DELETE_PRODUCT: {
            const productsList = state.productsList.filter((item) => item.itemId !== payload);
            return { ...state, productsList, isLoading: false, currentId: payload, currentItem: payload };
        }
        case CLEAR_ITEM:
            return { ...state, currentItem: '' };
        default:
            return state;
    }
};
