import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';

export interface DialogCloseButtonProps extends IconButtonProps {
    onClose?: () => void;
}

export function DialogCloseButton({ onClose, ...props }: DialogCloseButtonProps) {
    return (
        <IconButton
            onClick={onClose}
            color="inherit"
            size="small"
            {...props}
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                m: 1,
                zIndex: 10,
            }}
        >
            <Close />
        </IconButton>
    );
}
