import { useEffect } from 'react';

type Options = {
    onOtherKeyPress?: (e: KeyboardEvent) => void;
    onFocus?: () => void;
    requireShift?: boolean;
    customAction?: () => void;
};

export default function useInputFocusShortcut(
    key: string,
    inputRef: HTMLInputElement | undefined,
    { onOtherKeyPress, onFocus, requireShift = false, customAction }: Options = {},
) {
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    const modifierText = `${isMacLike ? '⌘' : 'CtRL+'}${requireShift ? '⇧' : ''}${key.toUpperCase()}`;

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    function handleKeyPress(e: KeyboardEvent) {
        if ((e.metaKey || e.ctrlKey) && e.key === key && (!requireShift || e.shiftKey)) {
            inputRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            e.stopPropagation();
            e.preventDefault();
            focusInputField();
            customAction?.();
            onFocus?.();
        } else if (onOtherKeyPress) {
            onOtherKeyPress(e);
        }
    }

    function focusInputField() {
        inputRef?.focus({ preventScroll: true });
    }

    return { modifierText };
}
