import '@mui/material/CssBaseline';
import '@whiz-cart/ui-shared/register';
import 'cross-state/mutative/register';
import 'cross-state/patches/register';
import 'cross-state/persist/register';
import 'cross-state/react/register';
import css from './index.module.less';
import './setupLogger';

import 'leaflet/dist/leaflet.css';
import ReactDOM from 'react-dom';
import { version } from '../package.json';
import App from './app';
import { appStatePersist } from './appState';
import pingService from './ping/ping.service';
import './registerWorkers';
import './sideEffects';

console.info('Version', version);

declare global {
    interface Window {
        integrityCheckService: any;
        cartService: any;
    }
}

const root = document.body.appendChild(document.createElement('div'));
root.className = css.root!;

(async () => {
    // Init services
    pingService.start();

    try {
        await appStatePersist.initialization;
    } finally {
        // Render
        const loading = document.querySelector('#loading');
        if (loading) document.body.removeChild(loading);
        ReactDOM.render(<App />, root);
    }
})();
