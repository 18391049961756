import { faTrianglePersonDigging } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function NotImplemented() {
    return (
        <div
            css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
            }}
        >
            <FontAwesomeIcon icon={faTrianglePersonDigging} size="10x" />
            <div>Noch nicht implementiert</div>
        </div>
    );
}
