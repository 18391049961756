import store from '../store';

export default () => {
    const originalTitle = document.title;
    const handle = store.subscribeState(
        'config.env.name',
        (name) => (document.title = `${name.slice(0, 1).toUpperCase()}${name.slice(1)} ${originalTitle}`),
    );
    return () => {
        handle();
        document.title = originalTitle;
    };
};
