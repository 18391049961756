import React from 'react';
import { ActionConfig, useAction } from './action';
import LoadingIndicator from '@whiz-cart/ui-shared/loading/loadingIndicator';
import css from './useActionLoadingIndicator.module.less';
import { useTranslator } from '@translate';

export function useActionLoadingIndicator(
    display: ActionConfig['display'],
    config?: Omit<ActionConfig, 'action' | 'display' | 'label' | 'icon'> | undefined,
) {
    const t = useTranslator();
    useAction({ isLoading: true, display, label: t('loading'), icon: <ActionLoadingIcon />, ...config }, '', [display]);
}

export function ActionLoadingIcon() {
    return <LoadingIndicator className={css.loading} size="1rem" thickness={6} noMargin center={false} />;
}
