// @ts-nocheck
import {
    CLEAN_LOCATIONS,
    CLOSE_SESSION,
    LOAD_DEVICES_COMPLETE,
    REMOVE_TICKET,
    SET_DEVICES_LOADING,
    UPDATE_DEVICE,
    UPDATE_DEVICE_LOCATIONS,
    UPDATE_TICKET,
    UPDATE_WEIGHTS,
} from './sessionMonitor.action';

const isTicketActive = (t) => t && !t.completedOn;

const updateSession = (devices, find, update, upsert) => {
    const _find = find instanceof Function ? find : (d) => d.deviceId === find;
    const _update = update instanceof Function ? update : () => update;
    let found;

    devices = devices.map((d) => {
        if (!_find(d)) return d;
        found = true;
        return _update(d);
    });

    if (!found && upsert) devices.push(_update());
    return devices;
};

const updateTicket = (devices, id, update, upsert) => {
    return updateSession(
        devices,
        (d) => d.tickets?.find((t) => t.id === id) || d.deviceId === update?.deviceId,
        (d) => {
            let found, updatedTicket;
            const tickets = (d?.tickets || []).map((t) => {
                if (t.id !== id) return t;
                found = true;
                return (updatedTicket = update);
            });

            if (!found && upsert) tickets.push((updatedTicket = update));
            return { deviceId: updatedTicket.deviceId, ...d, tickets: tickets.filter(isTicketActive) };
        },
        upsert,
    );
};

const updateWeights = (devices, { sessionGuid, actualWeight, expectedWeight, t }) => {
    return devices.map((device) => {
        if (device.sessionGuid !== sessionGuid) return device;
        if (device.weightUpdatedOn > t) return device;

        return {
            ...device,
            actualWeight: actualWeight ?? device.actualWeight,
            expectedWeight: expectedWeight ?? device.expectedWeight,
            weightUpdatedOn: t,
        };
    });
};

export default (
    state = {
        hasLoadedOnce: false,
        isLoading: false,
        devices: [],
    },
    { type, payload },
    { config } = {},
) => {
    switch (type) {
        case SET_DEVICES_LOADING:
            return { ...state, isLoading: payload };

        case LOAD_DEVICES_COMPLETE:
            return {
                ...state,
                hasLoadedOnce: true,
                isLoading: false,
                devices: payload,
            };

        case UPDATE_DEVICE:
            return {
                ...state,
                devices: updateSession(
                    state.devices.filter((d) => d.deviceId === payload.deviceId || d.sessionGuid !== payload.sessionGuid),
                    payload.deviceId,
                    (d) => ({ ...d, ...payload }),
                    true,
                ),
            };

        case CLOSE_SESSION:
            return {
                ...state,
                devices: updateSession(
                    state.devices,
                    (d) => d.sessionGuid === payload.sessionGuid,
                    (d) => ({ ...d, sessionGuid: undefined }),
                ),
            };

        case UPDATE_TICKET:
            return {
                ...state,
                devices: updateTicket(state.devices, payload.id, payload, true),
            };

        case REMOVE_TICKET:
            return {
                ...state,
                devices: updateTicket(state.devices, payload),
            };

        case UPDATE_DEVICE_LOCATIONS:
            return {
                ...state,
                devices: payload.reduce(
                    (devices, { deviceId, location }) => updateSession(devices, deviceId, (d) => ({ ...d, deviceId, location }), true),
                    state.devices,
                ),
            };

        case CLEAN_LOCATIONS:
            return {
                ...state,
                devices: state.devices.map((device) => ({
                    ...device,
                    location: new Date(device.location?.ts ?? 0) > new Date() - config.locationsStaleAfter ? device.location : null,
                })),
            };

        case UPDATE_WEIGHTS:
            return {
                ...state,
                devices: updateWeights(state.devices, payload),
            };

        default:
            return state;
    }
};
