import type { ActivePushTopic, PushTopic } from '@push/types';
import { UPDATE_PUSH_STATUS } from './push.action';

export interface PushState {
    supported: boolean | undefined;
    permission: PermissionState | undefined;
    topics: PushTopic[];
    topicsActive: ActivePushTopic[];
    inProgress: boolean;
}

export default (
    state: PushState = {
        supported: undefined,
        permission: undefined,
        topics: [],
        topicsActive: [],
        inProgress: false,
    },
    { type, payload }: { type: string; payload: any },
) => {
    switch (type) {
        case UPDATE_PUSH_STATUS:
            return { ...state, ...payload };
        default:
            if (state.topics.some((x) => typeof x === 'string')) {
                return {
                    ...state,
                    topics: [
                        ...state.topics.flatMap((x) => (typeof x === 'string' ? [{ type: 'store', topic: x }] : [])),
                        ...((state as any).topicsGlobal ?? []).map((x: any) => ({
                            type: 'global',
                            topic: x.topic,
                            storeGuid: x.storeGuid,
                        })),
                    ],
                };
            }

            return state;
    }
};
