import { AssignedLabel, ResolvedLabel } from '@whiz-cart/node-shared/models/label/itemLabel';
import { ParsedDate } from '@whiz-cart/node-shared/models/parsed/parsedDate';
import { UiProduct } from '@whiz-cart/node-shared/models/product/uiProduct';
import { z } from 'zod';
export type ActiveProductsFilter = 'all' | 'active' | 'inactive' | 'labelPriority';

export type ProductListProps = {
    query: string;
    skip: number;
    limit: number;
    storeGuid: string;
    filter: ActiveProductsFilter;
    cgId?: string;
    cgLongId?: string;
    cacheKey?: string;
    productLabel?: string;
};

export const ProductListResult = UiProduct.partial()
    .and(UiProduct.pick({ itemId: true }))
    .array();

export type ProductListResult = z.infer<typeof ProductListResult>;

export const ProductOverview = z.object({
    uiProduct: UiProduct,
    assignedLabels: AssignedLabel.array().default([]),
    excludedLabels: ResolvedLabel.array().default([]),
    labels: ResolvedLabel.array().default([]),
    lastSalesDate: ParsedDate.nullable(),
    textSources: z
        .object({
            names: z.object({
                epim: z.string().optional(),
                rwws: z.string().optional(),
                pos: z.string().optional(),
            }),
            brands: z.object({
                epim: z.string().optional(),
                rwws: z.string().optional(),
                pos: z.string().optional(),
            }),
            contents: z.object({
                epim: z.string().optional(),
                rwws: z.string().optional(),
                pos: z.string().optional(),
            }),
        })
        .optional(),
    importSources: z.object({
        rwws: z
            .object({
                filePath: z.string(),
                triggeredOn: z.string(),
            })
            .optional(),
        pos: z
            .object({
                filePath: z.string(),
                triggeredOn: z.string(),
            })
            .optional(),
    }),
    relatedProducts: UiProduct.array().optional(),
});

export type ProductOverview = z.infer<typeof ProductOverview>;

export const StoreProduct = z
    .object({
        itemId: z.string(),
        changedBy: z.string().nullish(),
        forceActive: z.boolean().nullish(),
        createdOn: z.string().nullish(),
        modifiedOn: z.string().nullish(),
    })
    .optional();
