import CPromise from '@whiz-cart/node-shared/cPromise';
import { request, RequestProps } from '@whiz-cart/ui-shared/request/request';
import { authService } from '../auth/auth.service';
import { EndpointKeys, EndpointParams, GetUrl, resolveEndpoint } from './resolveEndpoint';

export const endpoint = <Key extends EndpointKeys, _Endpoint = GetUrl<Key>>(
    name: Key,
    ...params: EndpointParams<Key> extends Record<string, never> ? [params?: Record<string, never>] : [params: EndpointParams<Key>]
) => {
    const url = resolveEndpoint(name, ...(params as any));

    const execute = <Res, Req>(method: string, data?: Req, options?: Omit<RequestProps<Res, Req>, 'url'>) =>
        new CPromise<Res>(async (resolve, reject, onCancel) => {
            let canceled;
            onCancel(() => (canceled = true));

            let r;
            try {
                if (canceled) return;

                r = request<Res, Req>({
                    method,
                    url,
                    accessToken: async (refresh) => {
                        if (refresh) {
                            authService.accessToken.invalidateCache(undefined);
                        }
                        const accessToken = await authService.accessToken.get(undefined);
                        return accessToken ?? null;
                    },
                    data,
                    ...options,
                });
                onCancel(r.cancel.bind(r));
                resolve(await r);
            } catch (e) {
                if (!r?.isCanceled) reject(e);
            }
        });

    return {
        url,
        get: <Res, Req = any>(options?: Omit<RequestProps<Res, Req>, 'url'>) => execute('GET', undefined, options),
        post: <Res, Req = any>(data?: Req, options?: Omit<RequestProps<Res, Req>, 'url'>) => execute('POST', data, options),
        put: <Res, Req = any>(data: Req, options?: Omit<RequestProps<Res, Req>, 'url'>) => execute('PUT', data, options),
        delete: <Res, Req = any>(data?: Req, options?: Omit<RequestProps<Res, undefined>, 'url'>) => execute('DELETE', data, options),
    };
};

export default endpoint;

Object.assign(globalThis, { endpoint });
