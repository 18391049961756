import lazy from '@whiz-cart/node-shared/lazy';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';
import { v4 as guid } from 'uuid';
import { serviceWorkerRegistration } from '../registerWorkers';

declare global {
    interface Window {
        __firebase?: firebase.app.App;
    }
}

if (import.meta.hot) {
    window.__firebase?.delete();
}

let app: typeof firebase | firebase.app.App = firebase;

if (config?.firebase) {
    app = firebase.initializeApp(config.firebase, guid());

    if (import.meta.hot) {
        window.__firebase = app;
    }
}

const init = lazy(async () => {
    const sw = await serviceWorkerRegistration;

    if (!sw) {
        throw new Error('Service worker not registered');
    }

    app.messaging().useServiceWorker(sw);
});

function messaging() {
    const messaging = app.messaging();

    return {
        ...messaging,
        async getToken(...args) {
            await init();
            return messaging.getToken(...args);
        },
        async deleteToken() {
            await init();
            return messaging.deleteToken();
        },
    } satisfies firebase.messaging.Messaging;
}

export default {
    ...app,
    messaging,
};
