// @ts-nocheck
import _ from 'lodash';

export default (
    state = {
        overview: [],
        shelvesToRestore: [],
        isLoading: false,
    },
    action,
) => {
    switch (action.type) {
        case 'LOADING_SHELVES':
            return {
                ...state,
                isLoading: false,
            };
        case 'LOAD_SHELVES':
            return {
                ...state,
                overview: action.payload,
                isLoading: false,
            };
        case 'DELETE_SHELVES':
            const overview = state.overview?.filter((s) => action.payload.indexOf(s.shelfGuid) === -1);
            const shelvesToRestore = state.overview
                ?.filter((s) => action.payload.indexOf(s.shelfGuid) !== -1)
                .map((s) => ({ ...s, storeGuid: action.storeGuid }))
                .concat(...state.shelvesToRestore);

            return {
                ...state,
                overview,
                shelvesToRestore,
                isLoading: false,
            };
        case 'RESTORE_SHELVES':
            const restore = [];
            for (const shelves of action.payload) {
                restore.push(state.shelvesToRestore.filter((s) => s.shelfGuid === shelves));
            }

            const result = _(state.overview)
                .concat(...restore)
                .orderBy('shelfGuid')
                .groupBy('shelfNo')
                .value();

            const newOverview = [];
            for (const i in result) {
                newOverview.push(result[i][0]);
            }

            return {
                ...state,
                overview: newOverview,
                isLoading: false,
            };
        default:
            return state;
    }
};
