// @ts-nocheck
import Bus from '@whiz-cart/node-shared/connection/bus';
import { ConnectionStatus } from '@whiz-cart/node-shared/connection/connection';
import SignalrConnection from '@whiz-cart/node-shared/connection/signalrConnection';
import sleep from '@whiz-cart/node-shared/sleep';
import { bound } from '@whiz-cart/ui-shared/decorators/bound';
import _ from 'lodash';
import { authService } from '../auth/auth.service';
import store from '../store';

export default class Connection extends Bus {
    refs = new Set();
    channel;
    storeConfig = {};
    staticConfig = {};
    name = '';

    constructor({ url, dependencies = {}, start, name }) {
        super();
        this.url = typeof url === 'function' ? url : () => url;

        store.subscribeState(
            (state) => _.mapValues(dependencies, (key) => (key instanceof Function ? key(state) : _.get(state, key))),
            this._update,
        );

        if (start) this.start();
        this.name = name;
    }

    get config() {
        return { ...this.storeConfig, ...this.staticConfig };
    }

    @bound start(config) {
        const configChanged = !_.isEqual(this.staticConfig, config);
        this.staticConfig = config;

        const ref = {};
        this.refs.add(ref);
        if (this.refs.size === 1 || configChanged) this._connect();

        return async () => {
            delete this.staticConfig;
            await sleep(1000); // Don't disconnect immediately. Otherwise connections are reset on some page changes.
            this.refs.delete(ref);
            if (this.refs.size === 0) this._disconnect();
        };
    }

    @bound async _update(config) {
        this.storeConfig = config;

        this._disconnect();
        if (this.refs.size > 0) this._connect();
    }

    async _connect() {
        this._disconnect();

        let url;
        if ((url = await this.url(this.config))) {
            this.updateChannel(
                new SignalrConnection(url, {
                    accessTokenFactory: async () => {
                        const token = await authService.accessToken.get(undefined);
                        if (!token) {
                            throw Error('No access token available for connection!');
                        }
                        return token;
                    },
                    debug: console.debug,
                }),
            );
            console.debug(`Started connection: ${this.name}`);
        }
    }

    _disconnect() {
        if (this.channelIn) {
            this.updateChannel();
            console.debug(`Stopped connection: ${this.name}`);
        }
    }

    onConnection(action, setLoading) {
        let timer, request;

        const trigger = async (preliminary) => {
            try {
                request = action();
                await request;
                setLoading?.(false);
                console.debug(`Executed after connection action: ${this.name}${preliminary ? ' (preliminary)' : ''}`);
            } catch (e) {
                if (e.isCanceled) return;
                console.error(`Failed to execute after connection action: ${this.name}`, e);
                setLoading?.(false);
            }
        };

        return this.subscribeStatus((status) => {
            clearTimeout(timer);
            request?.cancel?.();

            if (status === ConnectionStatus.Connecting) {
                setLoading?.(true);
                timer = setTimeout(() => trigger(true), store.getState().config.preliminaryLoadingTimeout ?? 3000);
            } else if (status === ConnectionStatus.Connected) {
                setLoading?.(true);
                trigger();
            }
        });
    }
}
