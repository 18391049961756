import { openDialog } from '@util/imperativeDialog';
import { request } from '@whiz-cart/ui-shared/request/request';
import { Action } from 'schummar-state/react';
import { authService } from '../auth/auth.service';
import store from '../store';
import ServiceVersions from './serviceVersions.component';

export default (window as any).serviceVersionsService = new (class {
    show() {
        openDialog(ServiceVersions);
    }

    getVersion = new Action(
        async (backend: string) => {
            const { backends, endpoints } = store.getState().config;
            const baseUrl = backends[backend as keyof typeof backends];
            const endpoint = endpoints[backend as keyof typeof endpoints];
            const path = 'version' in endpoint ? endpoint.version : 'version';

            const result = await request({
                method: 'GET',
                url: `${baseUrl}/${path}`,
                accessToken: () => authService.accessToken.get(undefined),
            });
            return result as { app: string; version: string };
        },
        { invalidateAfter: 60_000 },
    );
})();
