import { Action } from 'schummar-state/react';
import { authService } from '../auth/auth.service';
import { updateCartLoginTokens } from '../cartLogin/cartLogin.action';
import mhdSync from '../mhd/mhdSync';
import { clearProducts } from '../product/product.action';
import store from '../store';
import { clearStores } from '../store/store.action';

export default () => {
    return authService.loginState.subscribe(
        (state) => state.isLoggedIn,
        (isLoggedIn, prevIsLoggedIn) => {
            if (!isLoggedIn && prevIsLoggedIn) {
                setTimeout(() => {
                    store.dispatch(clearStores());
                    store.dispatch(clearProducts());
                    store.dispatch(updateCartLoginTokens([]));
                    mhdSync.clear();
                    Action.clearCacheAll();
                });
            }
        },
    );
};
