// @ts-nocheck
import { toast } from 'react-toastify';
import { t } from '../translate';
export const checkForChromeResetCode = (input) => {
    const resetCode =
        'SN9:JH50BRZeIG6EIuaHrT4kStrpwkONMio4KRFMnckkKD5oGOWqFfpZlzyHBr4b2vE8eCBCDg7WNdfWluXr1Q8zfop8jw6GGICNbMJxVtK7946mAM1AxDFSMXGu7JRAgZiKOoWnlWH2nOBk50DuONAvgVZCNxP4t6+hw8q6fTOFn+41Ps/bBWUzkynaLcI6gubqb09bmRYTALFrLONv3fkqsgQoBjk6MIwgDQwkMiItnOMhk1nsw55AM1o1iUKs5GQtGj7JK/9x/bjHRa7ZVBxm+T2D2dvE4e7Ig3Q9kJxeoemx55N1lOUbI9fM1GMKmJumxqYDtiOXJw87CV0IQq9eYcUtpTE8v0w6H99k6NeK53dSMCWj5SUPq96ZJJzcOS3k9yFNFw==';
    if (input == resetCode) {
        toast.info(t('barcodeScanner.chromeResetCode', { toastId: 'chromeReset' }));
        return true;
    } else return false;
};
