import { Store } from '@whiz-cart/node-shared/models/store';
import { LoginState, ScopeParameters } from './authTypes';
import { resolveRoles } from './resolveRoles';

export function calcActiveRoles(auth: LoginState | undefined | null, stores: Record<string, Store>, activeStoreGuid?: string) {
    function getActiveRoles(...[scope, scopeId]: ScopeParameters) {
        if (!scope) {
            scope = activeStoreGuid ? 'store' : 'global';
            scopeId = activeStoreGuid;
        } else if (scope === 'store') {
            scopeId ??= activeStoreGuid;
        }

        return resolveRoles(auth?.tokenDetails?.roles ?? [], stores, scope!, scopeId);
    }

    return getActiveRoles;
}
