// @ts-nocheck
import { TOGGLE_PRODUCT_START, TOGGLE_PRODUCT_SUCCEED, LOAD_PRODUCTS_LIST } from './productBrowser.action';

export default (
    state = {
        products: [],
        forceActive: '',
        isRowLoading: false,
        currentId: '',
        error: '',
    },
    action,
) => {
    switch (action.type) {
        case LOAD_PRODUCTS_LIST:
            return {
                products: action.payload,
            };
        case TOGGLE_PRODUCT_START:
            return {
                isRowLoading: true,
                error: false,
                currentId: action.payload,
                products: action.products,
            };

        case TOGGLE_PRODUCT_SUCCEED:
            const toggleItem = action.products.map((item) => {
                if (item.itemId === action.payload) {
                    item.forceActive = !action.forceActive;
                }
                return item;
            });

            return {
                currentId: toggleItem,
                isRowLoading: false,
                products: action.products,
            };

        default:
            return state;
    }
};
