import { authService } from '@auth/auth.service';
import config from '@config/config';
import { ReactIntegration, ReactRouterHistory, ReactRouterVersion } from '@grafana/faro-react';
import { Faro, LogLevel, TraceContext, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { errorToString } from '@whiz-cart/node-shared/errorToString';
import { AppinsightsWebLogger } from '@whiz-cart/node-shared/loggers/appinsightsWebLogger';
import { ConsoleLogger } from '@whiz-cart/node-shared/loggers/consoleLogger';
import logger, { patchConsole } from '@whiz-cart/node-shared/loggers/logger';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { Route } from 'react-router';
import { version } from '../package.json';

let faro: Faro | undefined;

if (import.meta.env.DEV) {
    // ignore
} else if (config.tracing?.type === 'grafana') {
    const backends = Object.values(config.backends).map((url) => new RegExp(`^${url}/.*`, 'i'));

    faro = initializeFaro({
        url: config.tracing.collector,
        ignoreErrors: ['ResizeObserver loop completed with undelivered notifications.'],

        app: {
            name: 'store-manager-ui',
            version,
            environment: config.env.id,
        },

        instrumentations: [
            ...getWebInstrumentations({
                captureConsoleDisabledLevels: [LogLevel.DEBUG],
            }),

            new TracingInstrumentation({
                instrumentationOptions: {
                    propagateTraceHeaderCorsUrls: backends,
                },
            }),

            new ReactIntegration({
                router: {
                    version: ReactRouterVersion.V5,
                    dependencies: {
                        history: urlService.history as ReactRouterHistory, // the history object used by react-router
                        Route, // Route component imported from react-router package
                    },
                },
            }),
        ],
    });
} else if (config.tracing?.key ?? config.appInsightsInstrumentationKey) {
    logger.logLevel = 'debug';
    logger.transports = [
        new ConsoleLogger({ logLevel: 'debug', browserMode: true }),
        new AppinsightsWebLogger({
            logLevel: 'error',
            history: urlService.history,
            key: config.tracing?.key ?? config.appInsightsInstrumentationKey ?? '',
        }),
    ];

    patchConsole({ errorOnly: true });
}

export function pushError(error: unknown) {
    if (!(error instanceof Error)) {
        error = new Error(errorToString(error));
    }

    faro?.api.pushError(error as Error);
}

export function pushEvent(name: string, attributes?: Record<string, string>, domain?: string) {
    faro?.api.pushEvent(name, attributes, domain);
}

export function pushMeasurement(payload: { type: string; values: Record<string, number>; timestamp?: string; trace?: TraceContext }) {
    faro?.api.pushMeasurement(payload);
}

authService.loginState.subscribe(
    (state) => state.tokenDetails,
    (tokenDetails) => {
        if (!tokenDetails) {
            faro?.api.resetUser();

            return;
        }

        faro?.api.setUser({
            username: tokenDetails.unique_name,
            attributes: {
                roles: [...new Set(tokenDetails.roles.flatMap((role) => role.roles))].join(','),
            },
        });
    },
);
