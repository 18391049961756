// @ts-nocheck
import { Button } from '@whiz-cart/ui-shared/button/button.component';
import { Form } from '@whiz-cart/ui-shared/form/form.component';
import { Input } from '@whiz-cart/ui-shared/form/input.component';
import { commonValidations } from '@whiz-cart/ui-shared/validations/commonValidations';
import React from 'react';
import { t } from '../translate';
import userService from '../user/user.service';
import css from './forgotPassword.module.less';

export default function ForgotPassword() {
    async function save({ email }: any) {
        await userService.forgotPassword(email.value);
    }

    return (
        <Form
            className={css.form}
            successClassName={css.changePasswordMessage}
            action={save}
            validations={{ email: commonValidations.email }}
            validationMessages={{ email: t('validation.email') }}
        >
            {({ email }: any, { valid, changed, processing, submit }: any) => (
                <>
                    <div className={css.title}>{t('profile.forgotPassword.title', undefined, { component: 'p' })}</div>

                    <div className={css.label}>{t('profile.email')}</div>
                    <Input {...email} className={css.input} type="email" autoComplete="email" />

                    <Button
                        className={css.button}
                        primary
                        inactive={!valid || !changed || processing}
                        spinner={processing}
                        onClick={submit}
                    >
                        {t('profile.forgotPassword.submit')}
                    </Button>
                </>
            )}
        </Form>
    );
}
