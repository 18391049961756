// @ts-nocheck
import { faExchangeAlt } from '@fortawesome/pro-solid-svg-icons';
import { Code } from '@whiz-cart/ui-shared/code/code.component';
import { overlayService } from '@whiz-cart/ui-shared/overlay/overlay.service';
import { Action } from 'schummar-state/react';
import { showDemoSessions } from '../demoSessions/demoSessions.action';
import store from '../store';
import endpoint from '../util/endpoint';
import css from './sessionTransfer.module.less';
import z from 'zod';
import { ParsedDate } from '@whiz-cart/node-shared/models/parsed/parsedDate';
import { fetchSession } from './sessionMonitorDetails.action';
import { ManualCheckType } from './manualCheck.component';

const SurveillanceTrackingDocument = z.object({
    uploadRequestStatus: z.literal('success').or(z.literal('failed')).or(z.literal('pending')).or(z.string().nullish()),
    deleteRequestStatus: z.literal('success').or(z.literal('notRequested')).or(z.string().nullish()),
    errorMessage: z.literal('Requested file not found').or(z.string().nullish()),
    file: z.string().nullish(),
    createdBy: z.string().nullish(),
    createdOn: ParsedDate.nullish(),
    modifiedBy: z.string().nullish(),
    modifiedOn: ParsedDate.nullish(),
    deletedBy: z.string().nullish(),
    deletedOn: ParsedDate.nullish(),
    sessionGuid: z.string(),
    storeGuid: z.string(),
});

export default (window as any).sessionService = new (class {
    transferSession(sessionGuid: string) {
        overlayService.overlay({
            icon: faExchangeAlt,
            title: 'Sessiontransfer',
            message: (
                <div>
                    <Code className={css.img} text={`whizcart.sessionTransfer;${sessionGuid}`} type="azteccode" />
                </div>
            ),
            canClose: true,
        });
    }

    toggleDemoMode(isDemoSessionVisible: boolean) {
        store.dispatch(showDemoSessions(isDemoSessionVisible));
    }

    setDefaultDemoMode(isDemoSessionVisible: boolean, storeGuid: string) {
        store.dispatch(showDemoSessions(isDemoSessionVisible, storeGuid));
    }

    downloadSessionVideo(storeGuid: string, sessionGuid: string) {
        return endpoint('storeManager.downloadSessionVideo', { storeGuid, sessionGuid }).get();
    }

    getAvailableSurveillanceSessions() {
        return endpoint('storeManager.availableSurveillanceSessions').get();
    }

    getSurveillanceSession = new Action(
        async ({ storeGuid, sessionGuid }: { storeGuid: string; sessionGuid: string }) => {
            const result = await endpoint('storeManager.getSurveillanceTrackingDocument', {
                storeGuid: storeGuid,
                sessionGuid: sessionGuid,
            }).get();
            return SurveillanceTrackingDocument.parse(result);
        },
        {
            invalidateAfter: (value, error) => {
                if (this.notPollingVideoStatus(value, error)) {
                    return Infinity;
                }
                return 5000;
            },
        },
    );

    notPollingVideoStatus(value: any, error: any) {
        // file not found or no video was requested
        return (
            value?.errorMessage === 'Requested file not found' ||
            error?.response?.status === 404 ||
            error?.response?.status === 403 ||
            value?.uploadRequestStatus == 'success'
        );
    }

    async requestSessionVideo(storeGuid: string, sessionGuid: string) {
        return endpoint('storeManager.requestSessionVideo', { storeGuid }).post({ storeGuid, sessionGuid });
    }

    async bookmark(storeGuid: string, sessionGuid: string, isBookmarked = true) {
        try {
            const response = await endpoint('storeManager.bookmark', { storeGuid, sessionGuid }).post(`${isBookmarked}`);
            return response;
        } catch (e) {
            console.error('Failed to mark session as suspicous', e);
            throw e;
        }
    }

    async tara(storeGuid: string, cartId: string, sessionGuid: string) {
        await endpoint('storeManager.tara', { storeGuid }).post({ cartId, sessionGuid });
    }

    async manualCheck(
        setManualCheckValue: (a: boolean) => void,
        updateShowManualCheckConfirmation: (a: boolean) => void,
        manualCheckValue: boolean,
        storeGuid: string,
        sessionGuid: string,
        checkType: ManualCheckType,
    ) {
        await endpoint('storeManager.manualCheck', {
            storeGuid: storeGuid,
            sessionGuid: sessionGuid,
        })
            .post(checkType)
            .then(() => {
                setManualCheckValue(!manualCheckValue);
                try {
                    store.dispatch(fetchSession(storeGuid, sessionGuid));
                } catch (error) {
                    console.error(error);
                }
            })
            .finally(() => {
                updateShowManualCheckConfirmation(false);
            });
    }
})();
