import type { ActivePushTopic, PushTopic } from '@push/types';
import type { ServiceWorkerMessage } from '@serviceWorker/serviceWorkerMessage';
import { errorCode, WhizCartError } from '@whiz-cart/node-shared/errorToString';
import asDict from '@whiz-cart/node-shared/models/helpers/asDict';
import Queue from '@whiz-cart/node-shared/queue';
import { service } from '@whiz-cart/node-shared/service/service';
import sleep from '@whiz-cart/node-shared/sleep';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import localforage from 'localforage';
import _ from 'lodash';
import { authService } from '../auth/auth.service';
import { getActiveRoles, hasAutoSubscribe } from '../auth/authHelpers';
import { serviceWorkerRegistration } from '../registerWorkers';
import store, { storeRehydration } from '../store';
import endpoint from '../util/endpoint';
import firebase from '../util/firebase';
import { updatePushStatus } from './push.action';

export const expandTopics = asDict({
    sessionMonitor: asDict({
        service: ['sessionMonitor_service'],
        detective: ['sessionMonitor_detective'],
        storeManager: ['sessionMonitor_service', 'sessionMonitor_detective'],
        rollout: ['sessionMonitor_service', 'sessionMonitor_detective'],
        admin: ['sessionMonitor_admin', 'sessionMonitor_service', 'sessionMonitor_detective'],
    }),
});

const firebaseMessagingDB = localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: 'firebase-messaging-database',
    storeName: 'firebase-messaging-store',
});

const firebaseInstallationsDB = localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: 'firebase-installations-database',
    storeName: 'firebase-installations-store',
});

export const pushService = service(
    'pushService',
    class PushService {
        queue = new Queue();
        isInitialized = false;

        reportError(error: WhizCartError, pushTopic?: ActivePushTopic, storeGuid?: string) {
            if (error.message) {
                console.error(error);
            }

            const topics = store
                .getState()
                .push.topics.map((x) =>
                    !pushTopic || (x.topic === pushTopic.topic && (x.type === 'store' ? storeGuid : x.storeGuid) === pushTopic.storeGuid)
                        ? { ...x, error }
                        : x,
                );

            store.dispatch(updatePushStatus({ topics }));
        }

        resetError(pushTopic?: ActivePushTopic, storeGuid?: string) {
            const topics = store
                .getState()
                .push.topics.map((x) =>
                    !pushTopic || (x.topic === pushTopic.topic && (x.type === 'store' ? storeGuid : x.storeGuid) === pushTopic.storeGuid)
                        ? { ...x, error: undefined }
                        : x,
                );

            store.dispatch(updatePushStatus({ topics }));
        }

        async deleteToken() {
            console.debug('Hard reset push notifications');

            try {
                await firebase.messaging().deleteToken();
                console.debug(`Deleted token on Firebase's end`);
            } catch (e) {
                console.error(`Failed to delete token on Firebase's end`, e);
            }

            try {
                await firebaseMessagingDB.clear();
                await firebaseInstallationsDB.clear();
                console.debug('Deleted firebase data from indexedDB');
            } catch (e) {
                console.error('Failed to delete token from indexedDB', e);
            }
        }

        /** Delete and reestablish all push subscriptions */
        updateSubscriptions = () => {
            this.queue.clear(null);
            this.queue.schedule(async () => {
                try {
                    store.dispatch(updatePushStatus({ inProgress: true }));
                    await storeRehydration;

                    const { url, push } = store.getState();
                    const currentStoreGuid = url.params?.storeGuid;
                    const loginState = authService.loginState.getState();
                    const roles = getActiveRoles(loginState)('store', currentStoreGuid);
                    const topics = calcTopics(push.topics, [...roles], currentStoreGuid);

                    if (!loginState.isLoggedIn) return;

                    const { publicVapidKey } = store.getState().config.firebase;
                    let topicsActive = [...(store.getState().push.topicsActive ?? [])];
                    const obsoleteTopics = topicsActive.filter((a) => {
                        return !topics.some((b) => a.topic === b.topic && a.storeGuid === b.storeGuid);
                    });

                    await Promise.all(
                        obsoleteTopics.map(async ({ topic, storeGuid }) => {
                            try {
                                if (storeGuid === null) {
                                    await endpoint('storeManager.unsubscribeGlobal', { topic }).delete();
                                } else {
                                    await endpoint('storeManager.unsubscribe', { storeGuid, topic }).delete();
                                }
                                topicsActive = topicsActive.filter((x) => x.topic !== topic && x.storeGuid !== storeGuid);
                            } catch (e) {
                                // ignore
                            }
                        }),
                    );

                    store.dispatch(updatePushStatus({ topicsActive }));

                    if (obsoleteTopics.length > 0) {
                        await this.deleteToken();
                    }

                    if (topics.length > 0) {
                        let token;

                        for (let tries = 0; ; tries++) {
                            try {
                                do {
                                    token = await firebase.messaging().getToken({
                                        vapidKey: publicVapidKey,
                                        serviceWorkerRegistration: await serviceWorkerRegistration,
                                    });
                                } while (!token);
                                console.debug('Token:', token);
                                break;
                            } catch (e) {
                                if (errorCode(e) === 'messaging/permission-blocked') {
                                    return this.reportError(
                                        new WhizCartError(e, {
                                            message: 'Unable to get permission to notify.',
                                            userMessage: 'Berechtigung für PushService nicht erteilt',
                                        }),
                                    );
                                }

                                if (errorCode(e) === 'messaging/unsupported-browser') {
                                    return this.reportError(
                                        new WhizCartError(e, {
                                            message: '',
                                            userMessage: 'Push Benachrichtigungen werden in diesem Browser nicht unterstützt',
                                        }),
                                    );
                                }

                                if (tries >= 10) {
                                    return this.reportError(
                                        new WhizCartError(e, {
                                            message: 'An error occurred while retrieving token.',
                                            userMessage: 'Einrichten des PushService fehlgeschlagen',
                                        }),
                                    );
                                }

                                console.warn('Failed to get token, retrying in 10s', e);
                                await sleep(10_000);
                            }
                        }

                        this.resetError();

                        await Promise.all(
                            topics.map(async ({ storeGuid, topic }) => {
                                try {
                                    if (storeGuid === null) {
                                        await endpoint('storeManager.pushSubscribeGlobal').post({ topic, token });
                                    } else {
                                        await endpoint('storeManager.pushSubscribe', { storeGuid }).post({ topic, token });
                                    }

                                    if (!topicsActive.some((a) => a.topic === topic && a.storeGuid === storeGuid)) {
                                        topicsActive.push({ topic, storeGuid });
                                    }
                                } catch (e) {
                                    this.reportError(
                                        new WhizCartError(e, {
                                            message: 'Failed to send notification to backend',
                                            userMessage: 'Einrichten der Benachrichtigungen im Backend fehlgeschlagen',
                                        }),
                                        { topic, storeGuid },
                                        currentStoreGuid,
                                    );
                                }
                            }),
                        );
                    }

                    store.dispatch(updatePushStatus({ topicsActive }));
                    console.debug('Push subscriptions:', currentStoreGuid, topicsActive);
                } catch (e) {
                    this.reportError(new WhizCartError(e, { message: 'Unexpected error' }));
                } finally {
                    store.dispatch(updatePushStatus({ inProgress: false }));
                }
            });
        };

        async unsubscribeAll() {
            const { topicsActive = [] } = store.getState().push;

            await Promise.all(
                topicsActive.map(async ({ topic, storeGuid }) => {
                    try {
                        if (storeGuid === null) {
                            await endpoint('storeManager.unsubscribeGlobal', { topic }).delete();
                        } else {
                            await endpoint('storeManager.unsubscribe', { topic, storeGuid }).delete();
                        }
                    } catch (e) {
                        // ignore
                    }
                }),
            );

            store.dispatch(updatePushStatus({ topics: [], topicsActive: [] }));
        }

        async toggleTopic(pushTopic: PushTopic, force?: boolean) {
            let topics = store.getState().push.topics ?? [];
            const isActive = topics.some(
                (x) => x.type === pushTopic.type && x.topic === pushTopic.topic && x.storeGuid === pushTopic.storeGuid,
            );

            if (force === false || (force === undefined && isActive)) {
                topics = topics.filter(
                    (x) => !(x.type === pushTopic.type && x.topic === pushTopic.topic && x.storeGuid === pushTopic.storeGuid),
                );
            } else if (!isActive) {
                topics = [...topics, pushTopic];
            }

            store.dispatch(updatePushStatus({ topics }));
            this.updateSubscriptions();
        }

        async resetGlobalTopic(topic: string) {
            const topics = store.getState().push.topics.filter((x) => !(x.type === 'global' && x.topic === topic));
            store.dispatch(updatePushStatus({ topics }));
            this.updateSubscriptions();
        }

        async sendMessage(
            topic = 'sessionMonitor_service',
            payload = {
                type: 'ticket',
                title: 'Cart 901 - SOS',
                body: `${new Date().toLocaleTimeString()} Preisabweichung`,
                tag: '1234567890',
                renotify: true,
                data: '/customer/901',
            },
        ) {
            const { storeGuid } = await store.awaitState('url.params');
            endpoint('storeManager.pushSend', { storeGuid, topic }).post(payload);
        }
    },
);

const calcTopics = (pushTopics: PushTopic[], roles: string[], storeGuid: string): ActivePushTopic[] => {
    return _.uniqBy(
        pushTopics
            .filter((pushTopic) => pushTopic.type !== 'store' || storeGuid)
            .map((pushTopic) => {
                if (pushTopic.type === 'store') {
                    return { topic: pushTopic.topic, storeGuid };
                }

                return { topic: pushTopic.topic, storeGuid: pushTopic.storeGuid };
            })
            .flatMap((pushTopic) => {
                const expand = expandTopics[pushTopic.topic];
                if (!expand) return [pushTopic];
                return roles.flatMap((role) => (expand[role] ?? []).map((topic) => ({ ...pushTopic, topic })));
            }),
        (x) => JSON.stringify([x.topic, x.storeGuid]),
    );
};

export const managePushSubscriptions = () => [
    store.subscribeState(
        ({ url, push }) => ({
            storeGuid: url.params?.storeGuid,
            permission: push.permission,
        }),
        async () => {
            pushService.updateSubscriptions();
        },
    ),

    authService.loginState.subscribe(
        (loginState) => loginState.isLoggedIn,
        async () => {
            pushService.updateSubscriptions();
        },
    ),

    authService.loginState.subscribe(hasAutoSubscribe, (autoSubscribe) => {
        if (!autoSubscribe) return;
        const topics = store.getState().push.topics ?? [];
        if (topics.some((x) => x.type === 'store' && x.topic === 'sessionMonitor')) return;
        store.dispatch(updatePushStatus({ topics: [...topics, 'sessionMonitor'] }));
    }),

    (() => {
        const messageListener = ({ data }: MessageEvent<ServiceWorkerMessage>) => {
            if (data.type !== 'navigate') {
                return;
            }

            console.debug('Received message from service worker to navigate to:', data.navigate);
            urlService.pushUrl(data.navigate);
        };
        navigator?.serviceWorker?.addEventListener('message', messageListener);
        return () => navigator?.serviceWorker?.removeEventListener('message', messageListener);
    })(),

    (() => {
        let cancel: () => void;

        (async () => {
            let stopped = false;
            cancel = () => (stopped = true);

            const navigatorPermissions = await navigator.permissions?.query({ name: 'notifications' });
            if (stopped) return;

            const updatePermission = async () => {
                const permission = navigatorPermissions?.state;

                store.dispatch(
                    updatePushStatus({
                        supported: !!window.navigator.serviceWorker,
                        permission,
                    }),
                );
            };

            updatePermission();
            navigatorPermissions?.addEventListener('change', updatePermission);
            cancel = () => navigatorPermissions?.removeEventListener('change', updatePermission);
        })();

        return () => cancel?.();
    })(),
];
