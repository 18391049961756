import _ from 'lodash';
import config from '../config/config';

type Config = typeof config;

///////////////////////////////////////////////////////////
// Types to extract endpoint keys and their parameters
export type EndpointKeys = keyof {
    [Backend in keyof Config['endpoints'] as `${Backend}.${keyof Config['endpoints'][Backend] & string}`]: 1;
};

type GetBackend<Key extends EndpointKeys> = Key extends `${infer Backend}.${any}` ? Backend & keyof Config['endpoints'] : never;
type GetEndpoint<Key extends EndpointKeys> = Key extends `${infer Backend}.${infer Endpoint}`
    ? Endpoint & keyof Config['endpoints'][Backend & keyof Config['endpoints']]
    : never;

type Params<T> = T extends `${any}{${infer Param}}${infer Rest}` ? { [K in Param]: string | number | boolean } & Params<Rest> : {};

export type GetUrl<Key extends EndpointKeys> = Config['endpoints'][GetBackend<Key>][GetEndpoint<Key>];
export type EndpointParams<Key extends EndpointKeys> = Params<GetUrl<Key>>;
///////////////////////////////////////////////////////////

export const resolveEndpoint = <Key extends EndpointKeys>(
    name: Key,
    ...[params]: EndpointParams<Key> extends Record<string, never> ? [params?: Record<string, never>] : [params: EndpointParams<Key>]
) => {
    const backends = config.backends as Record<string, string>;
    const endpoints = config.endpoints as Record<string, Record<string, string>>;

    let backendName: string | undefined, endpointName: string | undefined;
    if (name.includes('.')) {
        [, backendName, endpointName] = name.match(/(.*)\.(.*)/) ?? [];
    } else {
        endpointName = name;
        backendName = _.findKey(endpoints, (paths) => paths[name]);
    }

    const backend = backendName && backends[backendName];
    const path = backendName && endpointName && endpoints[backendName]?.[endpointName];

    if (!backend || path === undefined) throw new Error(`No config found for ${name}`);

    const url = `${backend}/${path}`;
    return url.replace(/\{([^}]*)\}/g, (_m, p) => (params as any)?.[p] ?? '');
};
