import { PartialTableTheme } from 'schummar-table';
import { t } from '../translate';
import { theme } from '../theme';

export const tableTheme: PartialTableTheme = {
    text: {
        exportDownload: t('table.exportDownload'),
        exportCopy: t('table.exportCopy'),
        exportTitle: t('table.exportTitle'),
        loading: t('table.loading'),
        noResults: t('table.noResults'),
        reset: t('table.reset'),
        selectColumns: t('table.selectColumns'),
        clearFilters: t('table.clearFilters'),
        deselectAll: t('table.deselectAll'),
        thisWeek: t('table.thisWeek'),
        today: t('table.today'),
        resetAll: t('table.resetAll'),
        lastThirtyDays: t('table.lastThirtyDays'),
        showAllColumns: t('table.showAllColumns'),
        hideAllColumns: t('table.hideAllColumns'),
        lastSevenDays: t('table.lastSevenDays'),
        rangeMax: t('table.rangeMax'),
        rangeMin: t('table.rangeMin'),
        thisMonth: t('table.thisMonth'),
        thisYear: t('table.thisYear'),
        calendarWeek: t('table.calendarWeekShort'),
    },
    colors: {
        primary: {
            main: theme.palette.primary.main,
            light: theme.palette.primary.light,
            contrastText: 'white',
        },
    },
};
