// @ts-nocheck
import { TOGGLE_NAVIGATION } from './navigation.action';

export default (state = document.body.offsetWidth > 599, { type, payload }) => {
    switch (type) {
        case TOGGLE_NAVIGATION:
            return payload !== undefined ? payload : !state;
        default:
            return state;
    }
};
