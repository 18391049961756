// @ts-nocheck
export const PRODUCTS_LIST = 'PRODUCTS_LIST';
export const LOADING_SEARCH_PRODUCT_LIST = 'LOADING_SEARCH_PRODUCT_LIST';
export const SEARCH_PRODUCTS_LIST = 'SEARCH_PRODUCTS_LIST';
export const CLEAR_SEARCH_PRODUCTS_LIST = 'CLEAR_SEARCH_PRODUCTS_LIST';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const CLEAR_ITEM = 'CLEAR_ITEM';

export const loadProducts = (list) => ({ type: PRODUCTS_LIST, payload: list });
export const loadSearchProducts = (list) => ({ type: SEARCH_PRODUCTS_LIST, payload: list });
export const clearSearchList = () => ({ type: CLEAR_SEARCH_PRODUCTS_LIST });
export const addProduct = (payload) => ({ type: ADD_PRODUCT, payload });
export const deleteProduct = (payload) => ({ type: DELETE_PRODUCT, payload });
export const clearItem = () => ({ type: CLEAR_ITEM });
export const isLoading = () => ({ type: LOADING_SEARCH_PRODUCT_LIST });
