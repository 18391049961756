// @ts-nocheck
import endpoint from '../util/endpoint';
import CPromise from '@whiz-cart/node-shared/cPromise';

export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const UPDATE_SESSION_COMPLETE = 'UPDATE_SESSION_COMPLETE';
export const UPDATE_SESSION_FAILED = 'UPDATE_SESSION_FAILED';
export const ADD_BASKET_CHANGE = 'ADD_BASKET_CHANGE';
export const ADD_TICKET_CHANGE = 'ADD_TICKET_CHANGE';
export const ADD_TARA_CHANGE = 'ADD_TARA_CHANGE';
export const ADD_IMAGE = 'ADD_IMAGE';
export const SESSION_SCALE_TRIGGER = 'SESSION_SCALE_TRIGGER';
export const SESSION_WEIGHT_DEVIATION = 'SESSION_WEIGHT_DEVIATION';
export const PAYMENT_REQUEST_CREATED = 'PAYMENT_REQUEST_CREATED';
export const PAYMENT_REQUEST_CHANGED = 'PAYMENT_REQUEST_CHANGED';
export const SESSION_WEIGHT_WITHOUT_SCAN = 'SESSION_WEIGHT_WITHOUT_SCAN';
export const SESSION_MANUAL_CHECK = 'SESSION_MANUAL_CHECK';

export const setSessionLoading = (sessionGuid, loading) => ({ type: SET_SESSION_LOADING, payload: { sessionGuid, loading } });

export const fetchSession = (storeGuid, sessionGuid) => (dispatch) =>
    new CPromise(async (resolve, reject, onCancel) => {
        let isCanceled = false;
        onCancel(() => (isCanceled = true));

        try {
            const [session, history] = await Promise.all([
                endpoint('storeManager.getSessionBasket', { storeGuid, sessionGuid }).get(),
                endpoint('storeManager.getSessionHistory', { storeGuid, sessionGuid }).get(),
            ]);
            if (!isCanceled) dispatch(updateSession({ storeGuid, sessionGuid, ...session, history }));
            resolve();
        } catch (e) {
            if (!isCanceled) dispatch({ type: UPDATE_SESSION_FAILED, payload: { sessionGuid, error: e } });
            reject(e);
        }
    });

export const updateSession = (session) => ({ type: UPDATE_SESSION_COMPLETE, payload: session });

export const addBasketChange = (item) => ({ type: ADD_BASKET_CHANGE, payload: item });

export const addTicketChange = (item) => ({ type: ADD_TICKET_CHANGE, payload: item });

export const addTaraChange = (item) => ({ type: ADD_TARA_CHANGE, payload: item });

export const addImage = (item) => ({ type: ADD_IMAGE, payload: item });

export const sessionScaleTrigger = (item) => ({ type: SESSION_SCALE_TRIGGER, payload: item });

export const sessionWeightWithoutScan = (item) => ({ type: SESSION_WEIGHT_WITHOUT_SCAN, payload: item });

export const sessionManualCheck = (item) => ({ type: SESSION_MANUAL_CHECK, payload: item });

export const sessionWeightDeviation = (item) => ({ type: SESSION_WEIGHT_DEVIATION, payload: item });

export const paymentRequestCreated = (item) => ({ type: PAYMENT_REQUEST_CREATED, payload: item });

export const paymentRequestChanged = (item) => ({ type: PAYMENT_REQUEST_CHANGED, payload: item });
