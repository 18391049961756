import { ParsedDate } from '@whiz-cart/node-shared/models/parsed/parsedDate';
import z from 'zod';

export const siblingProduct = z.object({
    itemId: z.string(),
    amount: z.number().nullish(),
    brand: z.string().nullish(),
    name: z.string().nullish(),
    cgIcon: z.string().nullish(),
    price: z.number().nullish(),
    gtin: z.string().nullish(),
});

export const checkItemCore = z.object({
    barcode: z.string(),
    itemFound: z.boolean().nullable(),
    amountInSession: z.number(),
    createdOn: ParsedDate.optional(),
    siblingProducts: z.array(siblingProduct).nullish(),
});
export type CheckItemCore = z.infer<typeof checkItemCore>;

export const integrityCheckItem = checkItemCore.merge(
    z.object({
        replacedCheckItem: checkItemCore.nullish(),
    }),
);
export type IntegrityCheckItem = z.infer<typeof integrityCheckItem>;

export const itegrityCheckItemContent = z.object({
    paymentRequestGuid: z.string(),
    sessionGuid: z.string(),
    storeGuid: z.string(),
    status: z.number(),
    cancelReason: z.string().nullish(),
    totalItemsToVerify: z.number(),
    createdBy: z.string().nullish(),
    lastModifiedBy: z.string().nullish(),
    scannedItems: z.array(integrityCheckItem),
    nonHomogenous: z.boolean(),
    createdOn: ParsedDate,
    modifiedOn: ParsedDate,
});

export type IntegrityCheckItemContent = z.infer<typeof itegrityCheckItemContent>;

export const integrityCheckItemResponse = z.object({
    content: itegrityCheckItemContent,
    status: z.number(),
    message: z.string().nullish(),
    contextValue: z.string().nullish(),
});

export type IntegrityCheckItemResponse = z.infer<typeof integrityCheckItemResponse>;
