import type { Brand } from '@whiz-cart/node-shared/models/brand/brand';
import type { UiProduct } from '@whiz-cart/node-shared/models/product/uiProduct';
import localforage from 'localforage';
import { Store, StorePersist } from 'schummar-state/react';

const storage = localforage.createInstance({ name: 'appState' });

type recentStoreItems = {
    [storeGuid: string]: { items: UiProduct[] };
};

export type CartSorting = { by: 'active' | 'lastSeen' | 'battery'; order: 'asc' | 'desc' };

export const appState = new Store({
    locale: undefined as string | undefined,
    recentProducts: {} as recentStoreItems,
    navigationGroupsCollapse: {} as { [groupName: string]: boolean },
    cartSorting: undefined as CartSorting | undefined,
    overrideBrand: undefined as Brand | undefined,
});

export const appStatePersist = new StorePersist(appState, storage, {
    paths: ['locale', 'recentProducts', 'navigationGroupsCollapse', 'cartSorting'],
});

if (typeof window !== 'undefined') {
    Object.assign(window, { appState, appStatePersist });
}
