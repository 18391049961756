// @ts-nocheck
import integrityCheckService from '@integrityCheck/integrityCheck.service';
import bus from '../connection/checkoutConnection';
import store from '../store';
import {
    cancelPayment,
    completePayment,
    getImages,
    lockPayment,
    lockPosProcessingPayment,
    setPaymentLoading,
    unlockPayment,
    unlockPosProcessingPayment,
    updatePayment,
    updatePayments,
    verifyPayment,
} from './checkout.action';
import checkoutService from './checkout.service';

export default () => [
    bus.subscribe('broadcastPayment', (data) =>
        store.dispatch(updatePayment(checkoutService.transformIncomingPayment(data, bus.config.storeGuid))),
    ),
    bus.subscribe('posBackchannelResultAvailable', (data) =>
        store.dispatch(updatePayment(checkoutService.transformIncomingPayment(data, bus.config.storeGuid))),
    ),

    bus.subscribe('paymentCompleted', (data) => store.dispatch(completePayment(data))),

    bus.subscribe('paymentCancelled', (data) => store.dispatch(cancelPayment(data))),

    bus.subscribe('paymentVerify', (data) => store.dispatch(verifyPayment(data))),

    bus.subscribe('paymentLock', (data) => store.dispatch(lockPayment(data))),

    bus.subscribe('paymentUnlock', (data) => store.dispatch(unlockPayment(data))),

    bus.subscribe('postProcessingPaymentLock', (data) => store.dispatch(lockPosProcessingPayment(data))),

    bus.subscribe('postProcessingPaymentUnlock', (data) => store.dispatch(unlockPosProcessingPayment(data))),

    bus.subscribe('expressSnapshot', (data) => store.dispatch(getImages(data))),

    bus.subscribe('basketIntegrityCheck', (data) => {
        integrityCheckService.getIntegrityCheckItem.setCache(
            {
                storeGuid: bus.config.storeGuid,
                paymentRequestGuid: data.paymentRequestGuid,
            },
            data,
        );
    }),

    bus.onConnection(
        () => checkoutService.getPendingRequests(bus.config.storeGuid).then((data) => store.dispatch(updatePayments(data))),
        (loading) => store.dispatch(setPaymentLoading(loading)),
    ),
];
