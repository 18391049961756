import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { authService } from '../auth/auth.service';
import { getActiveRoles, hasSingleStore } from '../auth/authHelpers';
import sessionService from '../sessionMonitor/session.service';
import store from '../store';
import { useEffect } from 'react';

if (!urlService.getUrl().params.storeGuid) {
    const remembered = localStorage.getItem('storeGuid');
    if (remembered) urlService.replaceUrl({ storeGuid: remembered || undefined }, urlService.getUrl().hash);
}

export default () => [
    store.subscribeState(
        ({ url }) => url.params?.storeGuid,
        (storeGuid) => {
            localStorage.setItem('storeGuid', storeGuid || '');

            getActiveRoles(authService.loginState.getState(), storeGuid)().has('admin')
                ? sessionService.setDefaultDemoMode(true, storeGuid)
                : sessionService.setDefaultDemoMode(false, storeGuid);
        },
    ),

    authService.loginState.subscribe(
        (state) => state.isLoggedIn,
        (isLoggedIn) => {
            if (isLoggedIn) {
                const storeGuid = store.getState().url.params.storeGuid;

                getActiveRoles(authService.loginState.getState(), storeGuid)().has('admin')
                    ? sessionService.setDefaultDemoMode(true, storeGuid)
                    : sessionService.setDefaultDemoMode(false, storeGuid);
            }
        },
    ),
];

export function useRedirectToStore(stores: any, storeGuid: string, url: any) {
    useEffect(() => {
        redirectUnauthorizedStore();
    }, [storeGuid]);

    const redirectUnauthorizedStore = () => {
        const arrayStores = Object.keys(stores);
        const haveAcces = storeGuid ? arrayStores.includes(storeGuid || '') : !hasSingleStore();

        if (!haveAcces) {
            const lastStore = url?.history.find((x: any) => arrayStores.includes(x.params.storeGuid));
            if (lastStore) {
                urlService.pushUrl({ storeGuid: lastStore.params.storeGuid }, urlService.getUrl().hash);
            } else {
                urlService.pushUrl({ storeGuid: arrayStores[0] }, urlService.getUrl().hash);
            }
        }
    };
}
