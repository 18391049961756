export const serviceWorkerRegistration = (async () => {
    if (!('serviceWorker' in navigator)) {
        return;
    }

    return await navigator.serviceWorker.register(import.meta.env.MODE === 'production' ? '/serviceWorker.js' : '/dev-sw.js?dev-sw', {
        scope: '/',
        type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
    });
})();
