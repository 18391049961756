// @ts-nocheck
import bus from '../connection/sessionConnection';
import store from '../store';
import { updateWeights } from './sessionMonitor.action';
import {
    addBasketChange,
    addImage,
    fetchSession,
    paymentRequestChanged,
    paymentRequestCreated,
    setSessionLoading,
} from './sessionMonitorDetails.action';

export default () => [
    bus.onConnection(
        () => {
            const { storeGuid, sessionGuid } = bus.config;
            if (!storeGuid || !sessionGuid) return;
            return store.dispatch(fetchSession(storeGuid, sessionGuid));
        },
        (loading) => store.dispatch(setSessionLoading(bus.config.sessionGuid, loading)),
    ),

    bus.subscribe('storeSessionBasketChanged', (item) => {
        store.dispatch(updateWeights({ ...item, sessionGuid: item.basketItem.sessionGuid, t: item.modifiedOn }));
        store.dispatch(addBasketChange(item));
    }),

    bus.subscribe('storeSessionSnapshotReceived', (item) => {
        store.dispatch(addImage(item));
    }),

    bus.subscribe('storeSessionPaymentRequestCreated', (item) => {
        store.dispatch(paymentRequestCreated(item));
    }),

    bus.subscribe('storeSessionPaymentRequestChanged', (item) => {
        store.dispatch(paymentRequestChanged(item));
    }),
];
