import { MasterDataType } from '@masterData/masterDataList';
import { errorToString } from '@whiz-cart/node-shared/errorToString';
import { ParsedDate } from '@whiz-cart/node-shared/models/parsed/parsedDate';
import { Store } from '@whiz-cart/node-shared/models/store';
import { MarketDtoV4Model } from '@whiz-cart/node-shared/models/storeCreation/marketDtoV4Model';
import { Milestone } from '@whiz-cart/node-shared/models/storeCreation/milestone';
import { NavigationStatus, VoucherPromotion } from '@whiz-cart/node-shared/models/storeCreation/milestoneField';
import { StoreWithMilestones, StoreWithMilestonesSlim } from '@whiz-cart/node-shared/models/storeCreation/storeWithMilestones';
import { service } from '@whiz-cart/node-shared/service/service';
import { keyBy } from 'lodash';
import { Action } from 'schummar-state/react';
import { z } from 'zod';
import { Tenants } from '../tenant/tenant';
import endpoint from '../util/endpoint';
import { useSelector } from 'react-redux';
import { request } from '@whiz-cart/ui-shared/request/request';
import { LocalazyCdn } from '@whiz-cart/node-shared/models/localazy/localazyCdn';
import { useMemo } from 'react';
import _ from 'lodash';
import { Asset } from '@whiz-cart/node-shared/models/erp/asset';

export type StoreTableEntry = z.infer<typeof StoreTableEntry>;

export const OpenHours = z.object({
    opens: z.string(),
    closes: z.string(),
});

export const StoreTableEntry = z.object({
    assets: z.array(Asset).default([]),
    storeGuid: z.string(),
    name: z.string().nullish(),
    tenant: z.string().nullish(),
    storeId: z.string().nullish(),
    externalStoreId: z.string().nullish(),
    storeNumber: z.string().nullish(),
    globalLookupNumber: z.string().nullish(),
    branch: z.string().nullish(),
    startsOn: ParsedDate.nullish(),
    inventoryManagementSystem: z.number().nullish(),
    rwwsMigrationDate: ParsedDate.nullish(),
    configs: z
        .object({
            MPOS_IP_ADDRESS: z.string().nullish(),
            JUMPHOST_IP_ADDRESS: z.string().nullish(),
            HAS_NAVIGATION: z.string().nullish(),
            UI_DEACTIVATE_NAVIGATION: z.string().nullish(),
            UI_DEACTIVATE_LOCATION_BASED_SORTING: z.string().nullish(),
            EASY_DEALS_FLYER_URL: z.string().nullish(),
        })
        .nullish(),
    flags: z
        .object({
            enablePosPromotions: z.boolean().nullish(),
            enableVrPayment: z.boolean().nullish(),
            enableDirectDebitPayment: z.boolean().nullish(),
        })
        .nullish(),
    city: z.string().nullish(),
    zipCode: z.string().nullish(),
    street: z.string().nullish(),
    storeManagerEmail: z.string().nullish(),
    numberOfCarts: z.number().nullish(),
    cartDeliveryDate: ParsedDate.nullish(),
    hasRamps: z.boolean().nullish(),
    hasSelfCheckout: z.boolean().nullish(),
    sosPhoneCount: z.number().nullish(),
    chargingBoxCount: z.number().nullish(),
    chargingStationCount: z.number().nullish(),
    chargingMobileChargersCount: z.number().nullish(),
    chargingSwitchCabinetCount: z.number().nullish(),
    esPremium: z.boolean().nullish(),
    esLight: z.boolean().nullish(),
    expressShopping: z.boolean().nullish(),
    navigationStatus: NavigationStatus.nullish(),
    voucherPromotions: VoucherPromotion.array().nullish(),
    costCenter: z.string().nullish(),
    floorArea: z.number().nullish(),

    fibuStoreName: z.string().nullish(),
    fibuCustomerNumber: z.string().nullish(),
    company: z.string().nullish(),
    operatingMode: z.string().nullish(),

    openingHours: z
        .object({
            monday: OpenHours.nullish(),
            tuesday: OpenHours.nullish(),
            wednesday: OpenHours.nullish(),
            thursday: OpenHours.nullish(),
            friday: OpenHours.nullish(),
            saturday: OpenHours.nullish(),
            sunday: OpenHours.nullish(),
        })
        .nullish(),
});

export enum GetStoreOptionsFlags {
    None = 0,
    VoucherPromotions = 1,
    Assets = 2,
}

class StoreService {
    allStores = new Action(async () => {
        const response = await endpoint('storeManager.stores').get();
        const stores = response as Store[];
        return keyBy(stores, 'storeGuid');
    });

    list = new Action(async ({ skip, limit, filter }: { skip: number; limit: number; filter?: string }) => {
        try {
            const response = (await endpoint('storeAdmin.listStores', { skip, count: limit }).get({ params: { filter } })) as unknown;
            const { items, count } = z
                .object({
                    items: StoreWithMilestonesSlim.array(),
                    count: z.number(),
                })
                .parse(response);

            return { items, count };
        } catch (e) {
            console.error('Failed to list stores', errorToString(e));
            throw e;
        }
    });

    listAll = new Action(async () => {
        const response: Record<string, any> = await endpoint('storeAdmin.listAllStores').post(
            [
                'WELCOME_STORE_NAME',
                'MPOS_IP_ADDRESS',
                'JUMPHOST_IP_ADDRESS',
                'HAS_NAVIGATION',
                'UI_DEACTIVATE_NAVIGATION',
                'UI_DEACTIVATE_LOCATION_BASED_SORTING',
                'EASY_DEALS_FLYER_URL',
            ],
            { params: { flags: GetStoreOptionsFlags.VoucherPromotions | GetStoreOptionsFlags.Assets } },
        );
        const stores = StoreTableEntry.array().parse(Object.values(response));

        for (const store of stores) {
            const hasNavigation = store.configs?.HAS_NAVIGATION?.toLowerCase() === 'true';
            const uiDeactivateNavigation = store.configs?.UI_DEACTIVATE_NAVIGATION?.toLowerCase() === 'true';
            const uiDeactivateLocationBasedSorting = store.configs?.UI_DEACTIVATE_LOCATION_BASED_SORTING?.toLowerCase() === 'true';

            store.navigationStatus ??=
                hasNavigation && !uiDeactivateNavigation && !uiDeactivateLocationBasedSorting
                    ? 'active'
                    : hasNavigation && uiDeactivateNavigation && uiDeactivateLocationBasedSorting
                      ? 'internal'
                      : 'off';
        }

        return stores;
    });

    get = new Action(async (storeGuid: string) => {
        try {
            const response = (await endpoint('storeAdmin.getStore', { storeGuid }).get()) as unknown;
            return StoreWithMilestones.parse(response);
        } catch (e) {
            console.error('Failed to get store', errorToString(e));
            throw e;
        }
    });

    searchStoresForImport = new Action(async (query: { name?: string; city?: string; zipCode?: string; gln?: string }) => {
        try {
            const response = await endpoint('storeAdmin.searchImportStores').post(query);
            return MarketDtoV4Model.passthrough().array().parse(response);
        } catch (e) {
            console.error('Failed to search stores for import', errorToString(e));
            throw e;
        }
    });

    async create(draft: { name: string; startsOn: string; storeId: string }) {
        const response = await endpoint('storeAdmin.createStore').post(draft);
        const store = StoreWithMilestones.parse(response);

        this.list.clearCacheAll();
        this.listAll.clearCacheAll();
        this.get.setCache(store.store.storeGuid, store);
        this.allStores.invalidateCacheAll();

        return store;
    }

    async importStore({ gln, ...body }: { gln: string; startsOn: string; tenant: string }) {
        const response = await endpoint('storeAdmin.importStore', { gln }).post(body);
        const storeGuid = z.string().parse(response);

        this.list.clearCacheAll();
        this.listAll.clearCacheAll();
        this.get.clearCache(storeGuid);
        this.allStores.invalidateCacheAll();

        return storeGuid;
    }

    async setStatus(storeGuid: string, isActive: boolean) {
        const status = isActive ? 'active' : 'inactive';
        const response = await endpoint('storeAdmin.setStoreStatus', { storeGuid, status }).post();
        const updatedStore = Store.parse(response);

        this.list.clearCacheAll();
        this.listAll.clearCacheAll();
        this.get.updateCache(storeGuid, (store) => {
            store.store = updatedStore;
        });

        return updatedStore;
    }

    async runMilestone(storeGuid: string, milestoneId: string, data?: { [key: string]: any }) {
        const response = (await endpoint('storeAdmin.runMilestone', { storeGuid, milestoneId }).post(data, {
            headers: { 'content-type': 'application/json' },
        })) as unknown;
        const {
            store: updatedStore,
            milestones: [updatedMilestone],
        } = z
            .object({
                store: Store,
                milestones: z.tuple([Milestone]),
            })
            .parse(response);

        this.list.clearCacheAll();
        this.listAll.clearCacheAll();
        this.get.updateCache(storeGuid, (store) => {
            store.store = updatedStore;
            const index = store.milestones.findIndex((x) => x.milestoneId === milestoneId);
            store.milestones[index] = updatedMilestone;
        });

        return updatedMilestone;
    }

    getStoreMasterData = new Action(async (type: MasterDataType) => {
        const response = await endpoint('storeAdmin.getStoreMasterData', { type }).get();
        const parsed = Tenants.parse(response);
        return parsed;
    });

    async addOrupdateStoreMasterData(data: { name: string; id: string; type: MasterDataType; value: string | undefined }) {
        this.getStoreMasterData.updateCache(data.type, (draft) => {
            const foundItem = draft.find((item: any) => data.id == item.id);
            if (foundItem) {
                foundItem.name = data.name;
            }
        });
        const response = await endpoint('storeAdmin.addOrupdateStoreMasterData').post(data);
        this.getStoreMasterData.invalidateCache(data.type);
        return response;
    }

    async deleteStoreMasterData(id: string) {
        const response = await endpoint('storeAdmin.deleteStoreMasterData', { id: id }).delete();
        this.getStoreMasterData.invalidateCacheAll();
        return response;
    }

    async runAllMilestones(storeGuid: string) {
        const response = await endpoint('storeAdmin.runAllMilestones', { storeGuid }).get();
        const store = StoreWithMilestones.parse(response);
        this.list.clearCacheAll();
        this.get.setCache(store.store.storeGuid, store);
    }

    getConfig = new Action(async (storeGuid?: string) => {
        if (!storeGuid) {
            return {};
        }
        const response = await endpoint('storeManager.getStoreConfig', { storeGuid }).get();
        return response as Record<string, string>;
    });

    getCartLanguages = new Action(async (localazyCdn: string) => {
        const response = await request.get(localazyCdn);
        const parsed = LocalazyCdn.parse(response);
        const result = Object.values(parsed.files)?.[0]?.locales;
        return result;
    });

    useCartUiLanguages() {
        const localazyCdn = useSelector((state) => state.config.localazyCdn);
        const [languages] = this.getCartLanguages.useAction(localazyCdn);
        return languages;
    }

    useStoreLookup() {
        const stores = useSelector((state: any) => state.stores.stores as Store[]);
        const storeGuidLookup = useMemo(() => _.keyBy(stores, 'storeGuid'), [stores]);
        return storeGuidLookup;
    }
}

export default service('storeService', StoreService);
