import guid from '@whiz-cart/node-shared/guid';
import { Store, createScope, createStore } from 'cross-state';
import { ScopeProvider } from 'cross-state/react';
import { ElementType, ReactNode, useMemo } from 'react';

export interface ImperativeDialogProps<T = void> {
    open: boolean;
    onClose: (result?: T) => void;
}

export type ImperativeDialog<T = void> = ElementType<ImperativeDialogProps<T>>;

interface ImperativeDialogInstance<T, P> {
    id: string;
    component: ElementType<ImperativeDialogProps<T> & P>;
    props?: P;
    resolve: (value?: T) => void;
    reject: (reason: unknown) => void;
}

const dialogScope = createScope({
    stack: [] as ImperativeDialogInstance<any, any>[],
});

const globalDialogs = createStore(dialogScope.defaultValue);

export function DialogProvider({ global, children }: { global?: boolean; children?: ReactNode }) {
    const store = useMemo(() => (global ? globalDialogs : createStore(dialogScope.defaultValue)), [global]);
    const active = store.map((state) => state.stack[0]).useStore();

    function close() {
        store.update((state) => {
            state.stack.pop();
        });
    }

    return (
        <ScopeProvider scope={dialogScope} store={store}>
            {active && (
                <active.component
                    open
                    onClose={(value?: any) => {
                        active.resolve(value);
                        close();
                    }}
                    {...active.props}
                />
            )}

            {children}
        </ScopeProvider>
    );
}

export function useDialog() {
    const store = dialogScope.useScope();
    return createOpenFunction(store);
}

export const openDialog = createOpenFunction(globalDialogs);

function createOpenFunction(
    store: Store<{
        stack: ImperativeDialogInstance<any, any>[];
    }>,
) {
    function open<T>(component: ElementType<ImperativeDialogProps<T>>): Promise<T | undefined> & { close: () => void };
    function open<const P, T>(
        component: ElementType<ImperativeDialogProps<T> & P>,
        props: P,
    ): Promise<T | undefined> & { close: () => void };
    function open<const P, T>(component: ElementType<ImperativeDialogProps<T> & P>, props?: P) {
        const id = guid();

        const promise = new Promise<unknown | undefined>((resolve, reject) => {
            store.update((state) => {
                state.stack.push({ id, component, props, resolve, reject });
            });
        });

        return Object.assign(promise, {
            close: () => {
                store.update((state) => {
                    state.stack = state.stack.filter((d) => d.id !== id);
                });
            },
        });
    }

    return open;
}
