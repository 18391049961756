import { UPDATE_PRODUCT, CLEAR_PRODUCTS, UPLOAD_ERROR, CLEAR_ERROR } from './product.action';

export default (state: Record<string, any> & { error: boolean } = { error: false }, { type, payload }: any) => {
    switch (type) {
        case UPDATE_PRODUCT:
            return (payload as { gtin: string; product: any }[]).reduce(
                (state, { gtin, product }) => {
                    state[gtin] = { ...state[gtin], ...product };
                    return state;
                },
                { ...state },
            );

        case CLEAR_PRODUCTS:
            return {};

        case UPLOAD_ERROR:
            return { ...state, error: payload };

        case CLEAR_ERROR:
            return { ...state, error: false };

        default:
            return state;
    }
};
