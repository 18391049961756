// @ts-nocheck
export const UPDATE_PAYMENTS = 'UPDATE_PAYMENTS';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const LOCK_PAYMENT = 'LOCK_PAYMENT';
export const UNLOCK_PAYMENT = 'UNLOCK_PAYMENT';
export const LOCK_POS_PROCESSING_PAYMENT = 'LOCK_POS_PROCESSING_PAYMENT';
export const UNLOCK_POS_PROCESSING_PAYMENT = 'UNLOCK_POS_PROCESSING_PAYMENT';
export const SHOW_IMAGES = 'SHOW_IMAGES';
export const CANCEL_PAYMENT = 'CANCEL_PAYMENT';
export const COMPLETE_PAYMENT = 'COMPLETE_PAYMENT';
export const VERIFY_PAYMENT = 'VERIFY_PAYMENT';
export const SET_PAYMENT_LOADING = 'SET_PAYMENT_LOADING';
export const LOAD_SESSION_AMOUNT = 'LOAD_SESSION_AMOUNT';
export const SET_CASHIER_GUID = 'SET_CASHIER_GUID';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';

export const updatePayments = (payments) => ({ type: UPDATE_PAYMENTS, payload: payments });
export const updatePayment = (payment) => ({ type: UPDATE_PAYMENT, payload: payment });
export const lockPayment = (payment) => ({ type: LOCK_PAYMENT, payload: payment });
export const unlockPayment = (payment) => ({ type: UNLOCK_PAYMENT, payload: payment });
export const lockPosProcessingPayment = (payment) => ({ type: LOCK_POS_PROCESSING_PAYMENT, payload: payment });
export const unlockPosProcessingPayment = (payment) => ({ type: UNLOCK_POS_PROCESSING_PAYMENT, payload: payment });
export const getImages = (snapshot) => ({ type: SHOW_IMAGES, payload: snapshot });
export const cancelPayment = (payment) => ({ type: CANCEL_PAYMENT, payload: payment });
export const completePayment = (payment) => ({ type: COMPLETE_PAYMENT, payload: payment });
export const verifyPayment = (payment) => ({ type: VERIFY_PAYMENT, payload: payment });
export const setPaymentLoading = (status) => ({ type: SET_PAYMENT_LOADING, payload: status });
export const setCashierGuid = (cashier) => ({ type: SET_CASHIER_GUID, payload: cashier });
export const clearImages = () => ({ type: CLEAR_IMAGES });
