import { Box } from '@mui/material';
import LoadingIndicator from '@whiz-cart/ui-shared/loading/loadingIndicator';
import { Suspense, lazy } from 'react';

const BuildMonitor = lazy(() => import('./buildMonitor'));

export default function BuildMonitorStandalone() {
    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'grid',
                gridTemplateRows: 'minmax(0, 1fr)',
                gridTemplateColumns: 'minmax(0, 1fr)',
            }}
        >
            <Suspense fallback={<LoadingIndicator label />}>
                <BuildMonitor />
            </Suspense>
        </Box>
    );
}
