import type { ServiceWorkerMessage } from '@serviceWorker/serviceWorkerMessage';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import hash from 'object-hash';
import { Store } from 'schummar-state/react';
import { compare } from 'semver';
import { version } from '../../package.json';
import config from '../config/config';
import { serviceWorkerRegistration } from '../registerWorkers';

export const updateState = new Store({
    forceUpdate: false,
    updateInProgress: false,
    updateReloadScheduled: false,
});

const UPDATE_PATH_BLACKLIST = [
    '/checkout/payment/',
    '/integrityCheck/details',
].map((pattern) => new RegExp(`^${pattern}`));

const scheduleUpdate = () => {
    if (updateState.getState().forceUpdate) {
        window.location.reload();
        return;
    }

    let prev = window.location.pathname;

    const cancel = urlService.history.listen((cur: any) => {
        if (cur.pathname !== prev && !UPDATE_PATH_BLACKLIST.some((pattern) => pattern.test(cur.pathname))) {
            cancel();
            window.location.reload();
        }

        prev = cur.pathname;
    });

    updateState.update((state) => {
        state.updateInProgress = false;
        state.updateReloadScheduled = true;
    });
};

export const checkForUpdate = async (force?: boolean) => {
    console.debug('Checking for service worker update...');

    updateState.update((state) => {
        state.forceUpdate = force ?? false;
    });

    const reg = await serviceWorkerRegistration;
    await reg?.update();
};

export const resetForceUpdate = () => {
    updateState.update((state) => {
        state.forceUpdate = false;
    });
};

export default () => {
    let disposed = false;
    let newWorker: ServiceWorker | undefined | null;
    const handles: (() => void)[] = [() => (disposed = true)];

    if (import.meta.env.PROD) {
        serviceWorkerRegistration?.then((reg) => {
            if (disposed || !reg) {
                return;
            }

            const onUpdateFound = () => {
                if (!reg.active) return console.debug('First install of service worker.');
                console.debug('Service worker update started...');

                updateState.update((state) => {
                    state.updateInProgress = true;
                });

                newWorker = reg.installing;

                newWorker?.addEventListener('statechange', onUpdateComplete);
                handles.push(() => newWorker?.removeEventListener('statechange', onUpdateComplete));
            };

            const onUpdateComplete = () => {
                if (newWorker?.state !== 'activated') return;

                console.debug('Service worker update complete. Checking version...');
                newWorker.postMessage({ type: 'version' });
                navigator?.serviceWorker?.addEventListener('message', onVersion);
            };

            const onVersion = (event: MessageEvent<ServiceWorkerMessage>) => {
                if (event.data.type !== 'version') {
                    return;
                }

                navigator?.serviceWorker?.removeEventListener('message', onVersion);
                const currentHash = hash([version, config]);
                console.debug(`worker version: ${event.data.version}, worker hash: ${event.data.hash}, current hash: ${currentHash}`);

                // If the hash is the same, we don't need to update
                if (currentHash === event.data.hash) {
                    console.debug('Service worker is up to date.');
                    return;
                }

                // If this version if below the force update version, we need to reload immediately
                if (event.data.forceUpdate && compare(version, event.data.forceUpdate) < 0) {
                    console.debug('Service worker force update.');
                    window.location.reload();
                    return;
                }

                console.debug('Service worker updated, schedule update.');
                scheduleUpdate();
            };

            reg.addEventListener('updatefound', onUpdateFound);
            handles.push(() => reg.removeEventListener('updatefound', onUpdateFound));
        });

        const intervalId = setInterval(checkForUpdate, config.updateInterval);
        handles.push(() => clearInterval(intervalId));

        checkForUpdate();
    }

    return handles;
};

Object.assign(window, { scheduleUpdate, checkForUpdate });
